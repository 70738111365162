import { Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';

import { ICommentLikeData } from '../../types';

type ICommentLikeTextContentProps = {
  data: ICommentLikeData;
} & TextProps;

export const CommentLikeTextContent: React.FC<ICommentLikeTextContentProps> = (
  props
) => {
  const { data, ...rest } = props;

  return (
    <Text {...rest}>
      <Semibold>{data.firstActor.displayName}</Semibold>{' '}
      {data.numUniqueActors > 1
        ? `and ${data.numUniqueActors - 1} other${
            data.numUniqueActors > 2 ? 's' : ''
          } `
        : ''}
      liked your comment
      <Semibold>{`${
        data.comment.bodyPlainText ? `: ${data.comment.bodyPlainText}` : ''
      }`}</Semibold>
    </Text>
  );
};
