import axios from 'axios';
import * as React from 'react';
import yn from 'yn';

import { logError, logInfo, logWarning } from '@/imports/logging/ClientLogger';

const INTERVAL_MS = 1000 * 60 * 5;
const CLIENT_BUNDLE_VERSION_POLLING_ENABLED = yn(
  process.env.NEXT_PUBLIC_CLIENT_BUNDLE_VERSION_POLLING_ENABLED
);
const VERSION = process.env.NEXT_PUBLIC_CLIENT_BUNDLE_VERSION;
if (!VERSION) {
  throw new Error('process.env.NEXT_PUBLIC_CLIENT_BUNDLE_VERSION is required');
}

export const useNewClientIsAvailable = (): {
  newClientIsAvailable: boolean;
} => {
  const [newClientIsAvailable, setNewClientIsAvailable] = React.useState(false);
  React.useEffect(() => {
    if (CLIENT_BUNDLE_VERSION_POLLING_ENABLED) {
      const interval = setInterval(async () => {
        const url = '/version.txt';
        try {
          const result = await axios.get(url, { responseType: 'text' });
          const detectedVersion = await result.data;
          if (!detectedVersion) {
            logWarning(
              `Detected falsey client bundle version: ${detectedVersion}. url: ${url}`
            );
          } else if (detectedVersion.trim() !== VERSION.trim()) {
            logInfo(
              `Detected new client bundle. This version ${VERSION.trim()} and detected version: ${detectedVersion.trim()}`
            );
            setNewClientIsAvailable(true);
          } else {
            // Probably not necessary, but defends against scenario where we accidentally get into true state
            setNewClientIsAvailable(false);
          }
        } catch (err) {
          logInfo(
            `Error occurred while polling for new client bundle.url ${url}`
          );
          logError(err);
        }
      }, INTERVAL_MS);
      return () => clearInterval(interval);
    }
    return undefined;
  }, []);
  return { newClientIsAvailable };
};
