import { Button, ModalFooter } from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';
import { StateResultsProvided } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';

export const GlobalSearchModalFooter = connectStateResults<
  StateResultsProvided & {
    onClose: () => void;
  }
>((props) => {
  const nbHits = Object.keys(props.allSearchResults).reduce(
    (value, key) => value + props.allSearchResults[key].nbHits,
    0
  );

  if (!nbHits) {
    return null;
  }

  return (
    <ModalFooter>
      <NextLink href={`/search?query=${props.searchState.query}`}>
        <Button
          variant="outline"
          colorScheme="gray"
          w="full"
          onClick={props.onClose}
        >
          See All
        </Button>
      </NextLink>
    </ModalFooter>
  );
});
