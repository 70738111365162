import { gql, useQuery } from '@apollo/client';
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import { MenuIcon } from '@/imports/ui/chakra/feather';
import { useAlphabetSoup } from '@/modules/alphabet-soup';

import { HeaderNavigation_Query } from './graphql-types/HeaderNavigation_Query';

import { HeaderNavigationMenuLink } from './components/HeaderNavigationMenuLink';
import { HeaderNavigationTabLink } from './components/HeaderNavigationTabLink';

const QUERY = gql`
  query HeaderNavigation_Query($input: NavigationInput!) {
    navigation(input: $input) {
      items {
        ...HeaderNavigationTabLink_NavigationItem
      }
      metadata {
        version
        publishedVersion
      }
    }
  }
  ${HeaderNavigationTabLink.navigationItemFragment}
`;

export const HeaderNavigationTabs: React.FC<BoxProps> = (props) => {
  const { isWebsiteBuilder } = useAlphabetSoup();

  const query = useQuery<HeaderNavigation_Query>(QUERY, {
    variables: {
      input: { isUnpublishedVersion: isWebsiteBuilder },
    },
  });

  if (!query.data?.navigation.items.length) {
    return null;
  }

  return (
    <Box display="flex" {...props}>
      {query.data?.navigation.items.map((item, index) => (
        <HeaderNavigationTabLink key={index + item.label} item={item} />
      ))}
    </Box>
  );
};

export const HeaderNavigationMenu: React.FC<IconButtonProps> = (props) => {
  const { isWebsiteBuilder } = useAlphabetSoup();
  const modal = useDisclosure();

  const query = useQuery<HeaderNavigation_Query>(QUERY, {
    variables: {
      input: { isUnpublishedVersion: isWebsiteBuilder },
    },
  });

  if (!query.data?.navigation.items.length) {
    return null;
  }

  return (
    <>
      <IconButton
        icon={<MenuIcon />}
        variant="outline"
        colorScheme="gray"
        onClick={modal.onOpen}
        {...props}
      />

      <Modal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        size="full"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody p="0">
            <Stack textAlign="center" spacing="0">
              {query.data?.navigation.items.map((item, index) => (
                <HeaderNavigationMenuLink
                  key={index + item.label}
                  item={item}
                />
              ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
