import { Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';

import { IParentCommentCommentData } from '../../types';

type IParentCommentCommentTextContentProps = {
  data: IParentCommentCommentData;
} & TextProps;

export const ParentCommentCommentTextContent: React.FC<
  IParentCommentCommentTextContentProps
> = (props) => {
  const { data, ...rest } = props;

  return (
    <Text {...rest}>
      <Semibold>{data.firstActor.displayName}</Semibold>{' '}
      {data.numUniqueCommenters > 1
        ? `and ${data.numUniqueCommenters - 1} other${
            data.numUniqueCommenters > 2 ? 's' : ''
          } `
        : ''}
      replied to your comment
      <Semibold>{`${
        data.comment.bodyPlainText ? `: ${data.comment.bodyPlainText}` : ''
      }`}</Semibold>
    </Text>
  );
};
