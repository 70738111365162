import { gql, useLazyQuery } from '@apollo/client';
import * as React from 'react';

import { RawDraftContentStateFragmentAST } from '@/fragments/RawDraftContentStateFragment';

import {
  useEntityFetchByIds_query,
  useEntityFetchByIds_queryVariables,
  useEntityFetchByIds_query_commentsByIds_results,
  useEntityFetchByIds_query_postsByIds_results,
  useEntityFetchByIds_query_usersByIds_results,
} from './graphql-types/useEntityFetchByIds_query';

const ENTITY_FETCH_QUERY = gql`
  query useEntityFetchByIds_query(
    $postIds: PostsByIdsInput!
    $userIds: UsersByIdsInput!
    $commentIds: CommentsByIdsInput!
  ) {
    usersByIds(input: $userIds) {
      results {
        displayName
        _id
        proxyProfilePictureUrl(
          input: { w: 140, h: 140, fit: "facearea", facepad: 10.0 }
        )
      }
    }
    postsByIds(input: $postIds) {
      results {
        _id
        title
        bodyPlainText
        poll {
          _id
          title
          descriptionContentState {
            ...RawDraftContentStateFragment
          }
        }
        blog {
          _id
          title
        }
        question {
          _id
          title
          body
        }
        answer {
          _id
          body
          questionId
        }
        eventAlertId
      }
    }
    commentsByIds(input: $commentIds) {
      results {
        _id
        bodyPlainText
        post {
          _id
          poll {
            _id
          }
          blog {
            _id
          }
          question {
            _id
          }
          answer {
            _id
            questionId
          }
          eventAlertId
        }
      }
    }
  }
  ${RawDraftContentStateFragmentAST}
`;

const initialState: IEntityIdToEntitiesMap = {
  posts: {},
  users: {},
  comments: {},
};

export type IEntityIdToEntitiesMap = {
  posts: Record<string, useEntityFetchByIds_query_postsByIds_results>;
  users: Record<string, useEntityFetchByIds_query_usersByIds_results>;
  comments: Record<string, useEntityFetchByIds_query_commentsByIds_results>;
};

type IUseEntityFetchByIdsResult = {
  fetchNewEntities: (input: {
    variables: useEntityFetchByIds_queryVariables;
  }) => void;

  entityIdsToEntities: IEntityIdToEntitiesMap;
  clearEntities: () => void;
  loading: boolean;
};

export function useEntityFetchByIds(): IUseEntityFetchByIdsResult {
  const [entityIdsToEntities, setEntityIdsToEntities] =
    React.useState(initialState);

  const [query, { data, loading }] = useLazyQuery<
    useEntityFetchByIds_query,
    useEntityFetchByIds_queryVariables
  >(ENTITY_FETCH_QUERY);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    const newPostIdsToPosts = data.postsByIds.results
      .filter((post) => !!post)
      .reduce((curr, post) => ({ ...curr, [post._id]: post }), {});

    const newUserIdsToUsers = data.usersByIds.results
      .filter((user) => !!user)
      .reduce((curr, user) => ({ ...curr, [user._id]: user }), {});

    const newCommentIdsToComments = data.commentsByIds.results
      .filter((comment) => !!comment)
      .reduce((curr, comment) => ({ ...curr, [comment._id]: comment }), {});

    setEntityIdsToEntities((prev) => {
      return {
        posts: { ...prev.posts, ...newPostIdsToPosts },
        users: { ...prev.users, ...newUserIdsToUsers },
        comments: { ...prev.comments, ...newCommentIdsToComments },
      };
    });
  }, [data]);

  const fetchNewEntities = React.useCallback(
    (input: { variables: useEntityFetchByIds_queryVariables }): void => {
      const userIdsToFetch = input.variables.userIds.userIds.filter(
        (userId) => !entityIdsToEntities.users[userId]
      );
      const postIdsToFetch = input.variables.postIds.postIds.filter(
        (postId) => !entityIdsToEntities.posts[postId]
      );
      const commentIdsToFetch = input.variables.commentIds.commentIds.filter(
        (commentId) => !entityIdsToEntities.comments[commentId]
      );
      if (
        userIdsToFetch.length > 0 ||
        postIdsToFetch.length > 0 ||
        commentIdsToFetch.length > 0
      ) {
        query({
          variables: {
            userIds: { userIds: userIdsToFetch },
            postIds: { postIds: postIdsToFetch },
            commentIds: { commentIds: commentIdsToFetch },
          },
        });
      }
    },
    [
      entityIdsToEntities.comments,
      entityIdsToEntities.posts,
      entityIdsToEntities.users,
      query,
    ]
  );

  const clearEntities = React.useCallback((): void => {
    setEntityIdsToEntities(initialState);
  }, []);

  return {
    entityIdsToEntities,
    fetchNewEntities,
    clearEntities,
    loading,
  };
}
