import { chakra } from '@chakra-ui/react';
import * as React from 'react';
import { HighlightProps } from 'react-instantsearch-core';
import { connectHighlight } from 'react-instantsearch-dom';

const _CustomHighlight: React.FC<HighlightProps> = ({
  highlight,
  attribute,
  hit,
}) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <chakra.span key={index} bg="blue.100">
            {part.value}
          </chakra.span>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  );
};

export const CustomHighlight = connectHighlight(_CustomHighlight);
