import { Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';

import { IPollVoteData } from '../../types';
import { getTextContentForPost } from '../../utils';

type IPollVoteTextContentProps = {
  data: IPollVoteData;
} & TextProps;

export const PollVoteTextContent: React.FC<IPollVoteTextContentProps> = (
  props
) => {
  const { data, ...rest } = props;
  const content = getTextContentForPost(data.post);

  return (
    <Text {...rest}>
      {data.numUniqueActors > 1
        ? `${data.numUniqueActors} people `
        : 'Someone '}
      voted on your poll
      <Semibold>{`${content ? `: ${content}` : ''}`}</Semibold>
    </Text>
  );
};
