import * as React from 'react';

// TODO: looks like using command+k opens the url bar on firefix - oops

export const useKeyCommand = (
  modifier: 'ctrlKey' | 'metaKey' | 'altKey' | 'shiftKey',
  key: string,
  callback: (event: KeyboardEvent) => void
) => {
  const callbackRef = React.useRef(callback);

  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if (event[modifier] && event.key === key) {
        callbackRef.current(event);
      }
    },
    [modifier, key]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);
};
