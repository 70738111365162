import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { Configure, Index } from 'react-instantsearch-dom';

import { useKeyCommand } from '@/hooks/useKeyCommand';
import { useMemberManagement } from '@/hooks/useMemberManagement';
import { SearchIcon } from '@/imports/ui/chakra/feather';
import { InstantSearchWithErrorLogging } from '@/imports/ui/components/search/InstantSearchWithErrorLogging';
import { useGlobalSearchContext } from '@/imports/ui/components/search/SearchProvider';

import { GlobalSearchModal } from './components/GlobalSearchModal';

export const GlobalSearch: React.FC = (props) => {
  const searchClient = useGlobalSearchContext();
  const memberManagement = useMemberManagement();
  const globalSearchModal = useDisclosure();
  const close = globalSearchModal.onClose;

  const router = useRouter();

  useKeyCommand('metaKey', 'k', globalSearchModal.onOpen);

  // https://nextjs.org/docs/api-reference/next/router#resetting-state-after-navigation
  React.useEffect(() => {
    close();
  }, [close, router.asPath]);

  if (
    !searchClient.client ||
    !searchClient.indexData ||
    memberManagement.loading ||
    !router.isReady
  ) {
    return (
      <Tooltip
        label="Your device is unable to connect to search service"
        isDisabled={memberManagement.loading}
      >
        <IconButton
          aria-label="Search"
          icon={<SearchIcon />}
          variant="outline"
          colorScheme="gray"
          isRound
          isDisabled
        />
      </Tooltip>
    );
  }

  return (
    <InstantSearchWithErrorLogging
      searchClient={searchClient.client}
      indexName={searchClient.indexData.postIndexName}
    >
      <Index indexName={searchClient.indexData.memberIndexName}>
        <Configure
          hitsPerPage={5}
          restrictSearchableAttributes={[
            'displayName',
            searchClient.indexData.memberIndexSearchableField,
          ]}
          filters={searchClient.indexFilterData?.memberIndexFilter}
          attributesToRetrieve={[
            'displayName',
            searchClient.indexData.memberIndexViewableField,
            'userId',
            'profilePictureUrl',
          ]}
        />
      </Index>
      <Index indexName={searchClient.indexData.orgIndexName}>
        <Configure
          hitsPerPage={5}
          filters={searchClient.indexFilterData?.orgIndexFilter}
        />
      </Index>
      <Index indexName={searchClient.indexData.eventIndexName}>
        <Configure hitsPerPage={5} />
      </Index>
      <Index indexName={searchClient.indexData.fileIndexName}>
        <Configure hitsPerPage={5} />
      </Index>
      <Index indexName={searchClient.indexData.postIndexName}>
        <Configure hitsPerPage={5} />
      </Index>

      <IconButton
        aria-label="Search"
        icon={<SearchIcon />}
        variant="outline"
        colorScheme="gray"
        isRound
        onClick={globalSearchModal.onOpen}
      />

      <GlobalSearchModal
        isOpen={globalSearchModal.isOpen}
        onClose={globalSearchModal.onClose}
      />
    </InstantSearchWithErrorLogging>
  );
};
