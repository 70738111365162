import {
  useEntityFetchByIds_query_commentsByIds_results,
  useEntityFetchByIds_query_commentsByIds_results_post,
  useEntityFetchByIds_query_postsByIds_results,
  useEntityFetchByIds_query_usersByIds_results,
} from '../../hooks/graphql-types/useEntityFetchByIds_query';

export enum TRADEWING_POST_TYPES {
  POST = 'POST',
  ANSWER = 'ANSWER',
  POLL = 'POLL',
  BLOG = 'BLOG',
  QUESTION = 'QUESTION',
  EVENT_ALERT = 'EVENT_ALERT',
}

export enum ETradewingActivityGroupType {
  POST_LIKE = 'POST_LIKE',
  PARENT_POST_COMMENT = 'PARENT_POST_COMMENT',
  PARENT_COMMENT_COMMENT = 'PARENT_COMMENT_COMMENT',
  SIBLING_COMMENT = 'SIBLING_COMMENT',
  COMMENT_AT_MENTION = 'COMMENT_AT_MENTION',
  POST_AT_MENTION = 'POST_AT_MENTION',
  QUESTION_ANSWER = 'QUESTION_ANSWER',
  COMMENT_LIKE = 'COMMENT_LIKE',
  POLL_VOTE = 'POLL_VOTE',
}

export type IEnrichmentComment =
  useEntityFetchByIds_query_commentsByIds_results;
export type IEnrichmentUser = useEntityFetchByIds_query_usersByIds_results;
export type IEnrichmentPost = useEntityFetchByIds_query_postsByIds_results;
export type ICommentEnrichmentPost =
  useEntityFetchByIds_query_commentsByIds_results_post;

type IBaseEnrichedActivityGroup = {
  activity_count: number;
  is_read: boolean;
  is_seen: boolean;
  id: string;
  updated_at: string;
};

export type IActivityGroupWithActors = {
  firstActor: IEnrichmentUser;
  secondActor?: IEnrichmentUser;
};

export type IParentPostCommentData = {
  post: IEnrichmentPost;
  postType: TRADEWING_POST_TYPES;
  numUniqueCommenters: number;
  activityGroupType: ETradewingActivityGroupType.PARENT_POST_COMMENT;
  mostRecentComment: IEnrichmentComment;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type IParentCommentCommentData = {
  comment: IEnrichmentComment;
  numUniqueCommenters: number;
  activityGroupType: ETradewingActivityGroupType.PARENT_COMMENT_COMMENT;
  mostRecentComment: IEnrichmentComment;
  parentPost: IEnrichmentPost;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type ISiblingCommentData = {
  numUniqueCommenters: number;
  activityGroupType: ETradewingActivityGroupType.SIBLING_COMMENT;
  mostRecentComment: IEnrichmentComment;
  parentPost: IEnrichmentPost;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type IPostLikeData = {
  post: IEnrichmentPost;
  numUniqueActors: number;
  postType: TRADEWING_POST_TYPES;
  activityGroupType: ETradewingActivityGroupType.POST_LIKE;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type ICommentLikeData = {
  comment: IEnrichmentComment;
  numUniqueActors: number;
  activityGroupType: ETradewingActivityGroupType.COMMENT_LIKE;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type ICommentAtMentionData = {
  comment: IEnrichmentComment;
  numUniqueCommenters: number;
  activityGroupType: ETradewingActivityGroupType.COMMENT_AT_MENTION;
  mostRecentComment: IEnrichmentComment;
  parentPost: IEnrichmentPost;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type IPostAtMentionData = {
  post: IEnrichmentPost;
  numUniquePosters: number;
  postType: TRADEWING_POST_TYPES;
  activityGroupType: ETradewingActivityGroupType.POST_AT_MENTION;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type IQuestionAnswerData = {
  post: IEnrichmentPost;
  numUniqueActors: number;
  activityGroupType: ETradewingActivityGroupType.QUESTION_ANSWER;
  mostRecentAnswer: IEnrichmentPost;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type IPollVoteData = {
  post: IEnrichmentPost;
  numUniqueActors: number;
  activityGroupType: ETradewingActivityGroupType.POLL_VOTE;
} & IBaseEnrichedActivityGroup &
  IActivityGroupWithActors;

export type IEnrichedActivityGroup =
  | IParentCommentCommentData
  | IParentPostCommentData
  | IPostLikeData
  | ISiblingCommentData
  | ICommentAtMentionData
  | IPostAtMentionData
  | IQuestionAnswerData
  | ICommentLikeData
  | IPollVoteData;
