import { gql, useQuery } from '@apollo/client';
import { ModalBody, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import {
  AllSearchResults,
  connectAutoComplete,
} from 'react-instantsearch-core';

import {
  IAutoCompleteSearch,
  IEventSearch,
  IFileSearch,
  IMemberSearch,
  IOrganizationSearch,
  IPostSearch,
} from '@/client/legacy-shared-modules/search/search-types';
import { useGlobalSearchContext } from '@/imports/ui/components/search/SearchProvider';

import { GlobalSearchModalBody_Tenant } from './graphql-types/GlobalSearchModalBody_Tenant';

import { Event } from './components/Event';
import { File } from './components/File';
import { Organization } from './components/Organization';
import { Post } from './components/Post';
import { User } from './components/User';

const PRIORITY: Record<string, number> = {
  members: 1,
  organizations: 2,
  events: 3,
  files: 4,
  posts: 5,
};

const TENANT = gql`
  query GlobalSearchModalBody_Tenant {
    tenant {
      result {
        _id
        organizationEntityName
      }
    }
  }
`;

export const GlobalSearchModalResults = connectAutoComplete<
  AllSearchResults<IAutoCompleteSearch>
>((props) => {
  const query = useQuery<GlobalSearchModalBody_Tenant>(TENANT);
  const { indexData } = useGlobalSearchContext();

  if (!indexData) {
    return null;
  }

  if (!props.hits.some(({ hits }) => hits.length)) {
    return null;
  }

  const LABEL: Record<string, string> = {
    members: 'Members',
    organizations:
      query.data?.tenant.result?.organizationEntityName ?? 'Sponsors',
    events: 'Events',
    files: 'Files',
    posts: 'Posts',
  };

  return (
    <ModalBody borderTop="1px" borderBottom="1px" borderColor="gray.200">
      <Stack>
        {[...props.hits]
          .sort((a, b) =>
            PRIORITY[a.index] < PRIORITY[b.index] ? -1 : a > b ? 1 : 0
          )
          .map(({ index, hits }) => {
            if (!hits.length) {
              return null;
            }

            return (
              <Stack key={index}>
                <Text
                  textStyle="detailStrong"
                  textTransform="uppercase"
                  color="gray.500"
                >
                  {LABEL[index]}
                </Text>

                {hits.map((hit) => {
                  if (index === indexData.memberIndexName) {
                    return (
                      <User
                        key={hit.objectID}
                        hit={hit as IMemberSearch}
                        memberIndexViewableField={
                          indexData.memberIndexViewableField
                        }
                      />
                    );
                  }

                  if (index === indexData.fileIndexName) {
                    return <File key={hit.objectID} hit={hit as IFileSearch} />;
                  }

                  if (index === indexData.postIndexName) {
                    return <Post key={hit.objectID} hit={hit as IPostSearch} />;
                  }

                  if (index === indexData.eventIndexName) {
                    return (
                      <Event key={hit.objectID} hit={hit as IEventSearch} />
                    );
                  }

                  if (index === indexData.orgIndexName) {
                    return (
                      <Organization
                        key={hit.objectID}
                        hit={hit as IOrganizationSearch}
                      />
                    );
                  }

                  return null;
                })}
              </Stack>
            );
          })}
      </Stack>
    </ModalBody>
  );
});
