import { Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';

import { ISiblingCommentData } from '../../types';

type ISiblingCommentTextContentProps = {
  data: ISiblingCommentData;
} & TextProps;

export const SiblingCommentTextContent: React.FC<
  ISiblingCommentTextContentProps
> = (props) => {
  const { data, ...rest } = props;

  return (
    <Text {...rest}>
      <Semibold>{data.firstActor.displayName}</Semibold>{' '}
      {data.numUniqueCommenters > 1
        ? `and ${data.numUniqueCommenters - 1} other${
            data.numUniqueCommenters > 2 ? 's' : ''
          } `
        : ''}
      replied to your comment
    </Text>
  );
};
