import * as React from 'react';

type AlphabetSoup = {
  isWebsiteBuilder: boolean;
  highlightedContentIndex: {
    value: number | null;
    shouldScroll: boolean;
  };
  setHighlightedContentIndex: (value: {
    value: number | null;
    shouldScroll: boolean;
  }) => void;
  carouselIndex: number | undefined;
  setCarouselIndex: (value: number | undefined) => void;
};

const AlphabetSoupContext = React.createContext<AlphabetSoup>({
  isWebsiteBuilder: false,
  highlightedContentIndex: {
    value: null,
    shouldScroll: false,
  },
  setHighlightedContentIndex: () =>
    console.error('Attempted to use Alphabet Soup without a provider.'),
  carouselIndex: 0,
  setCarouselIndex: () =>
    console.error('Attempted to use Alphabet Soup without a provider.'),
});

export const useAlphabetSoup = () => {
  return React.useContext(AlphabetSoupContext);
};

export const AlphabetSoupProvider: React.FC<{ isWebsiteBuilder: boolean }> = (
  props
) => {
  const [highlightedContentIndex, setHighlightedContentIndex] = React.useState<{
    value: number | null;
    shouldScroll: boolean;
  }>({ value: null, shouldScroll: false });

  const [carouselIndex, setCarouselIndex] = React.useState<number | undefined>(
    undefined
  );

  React.useEffect(() => {
    setCarouselIndex(undefined);
  }, [highlightedContentIndex]);

  return (
    <AlphabetSoupContext.Provider
      value={{
        ...props,
        highlightedContentIndex,
        setHighlightedContentIndex,
        carouselIndex,
        setCarouselIndex,
      }}
    >
      {props.children}
    </AlphabetSoupContext.Provider>
  );
};
