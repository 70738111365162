import { Box, Link, LinkBox, LinkOverlay, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import NextLink from 'next/link';
import * as React from 'react';

import { SearchHelpers } from '@/client/legacy-shared-modules/search/helpers';
import { IFileSearch } from '@/client/legacy-shared-modules/search/search-types';
import { CustomHighlight } from '@/components/CustomHighlight';
import { FileIcon } from '@/imports/ui/chakra/feather';

dayjs.extend(relativeTime);

export const File: React.FC<{ hit: IFileSearch }> = ({ hit }) => {
  return (
    <LinkBox
      as={Stack}
      direction="row"
      spacing="2"
      alignItems="center"
      p="2"
      bg="white"
      _hover={{ bg: 'gray.100' }}
      borderRadius="xl"
      cursor="pointer"
    >
      {/* TODO: get different icon depending on file type */}
      <FileIcon boxSize="8" />

      <Box>
        <LinkOverlay as={NextLink} href={SearchHelpers.getFileUrl(hit)}>
          <Text noOfLines={1} textStyle="bodyStrong">
            <CustomHighlight attribute="file.name" hit={hit} />
          </Text>
        </LinkOverlay>

        <Text noOfLines={1} textStyle="compact" color="gray.600">
          {dayjs(hit.dateCreated).fromNow()} by{' '}
          <Link as={NextLink} href={`/members/${hit.author._id}`}>
            <CustomHighlight attribute="author.firstName" hit={hit} />{' '}
            <CustomHighlight attribute="author.lastName" hit={hit} />
          </Link>
        </Text>
      </Box>
    </LinkBox>
  );
};
