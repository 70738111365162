import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as React from 'react';

import { SearchBox } from '@/imports/ui/components/algolia/SearchBox';

import { GlobalSearchModalFooter } from './components/GlobalSearchModalFooter';
import { GlobalSearchModalNoResults } from './components/GlobalSearchModalNoResults';
import { GlobalSearchModalResults } from './components/GlobalSearchModalResults';

export const GlobalSearchModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader>
          <SearchBox placeholder="Search Community" />
        </ModalHeader>
        <GlobalSearchModalResults />
        <GlobalSearchModalNoResults />
        <GlobalSearchModalFooter onClose={props.onClose} />
      </ModalContent>
    </Modal>
  );
};
