import { gql } from '@apollo/client';
import {
  Box,
  Center,
  CenterProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackDivider,
  forwardRef,
} from '@chakra-ui/react';
import { DocumentNode } from 'graphql';
import NextLink from 'next/link';
import * as React from 'react';

import { useRouteMatch } from '@/hooks/useRouteMatch';
import { ChevronDownIcon, ExternalLinkIcon } from '@/imports/ui/chakra/feather';
import { useBlueprint } from '@/layouts/components/Blueprint';
import { getExternalUrl } from '@/utilities/getExternalUrl';

import { HeaderNavigationTabLink_NavigationItem } from './graphql-types/HeaderNavigationTabLink_NavigationItem';

const Tab = forwardRef<CenterProps & { isRouteMatch: boolean }, 'div'>(
  ({ isRouteMatch, ...props }, ref) => {
    return (
      <Center
        px="4"
        fontSize="lg"
        fontWeight="semibold"
        color={isRouteMatch ? 'gray.800' : 'gray.500'}
        borderBottom="2px"
        borderColor={isRouteMatch ? 'primary.500' : 'transparent'}
        cursor="pointer"
        ref={ref}
        {...props}
      />
    );
  }
);

Tab.displayName = 'Tab';

export const HeaderNavigationTabLink: React.FC<{
  item: HeaderNavigationTabLink_NavigationItem;
}> & { navigationItemFragment: DocumentNode } = ({ item }) => {
  const { forceMatchHack } = useBlueprint();

  const isRouteMatch =
    useRouteMatch(
      item.href === '/my-company/dashboard/overview' ? '/my-company' : item.href
    ) || (forceMatchHack ? item.href === forceMatchHack : false);

  const truncatedLabel =
    item.label.length > 25
      ? item.label.slice(0, 22).trim() + '...'
      : item.label;

  if (item.href) {
    if (item.isExternal) {
      return (
        <Tab
          as="a"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(item.href)}
          isRouteMatch={isRouteMatch}
        >
          {truncatedLabel}
        </Tab>
      );
    }

    return (
      <Tab as={NextLink} href={item.href} isRouteMatch={isRouteMatch}>
        {truncatedLabel}
      </Tab>
    );
  }

  if (item.children.length && item.children.some((child) => child.href)) {
    return (
      <Popover placement="bottom" trigger="hover">
        <PopoverTrigger>
          <Tab isRouteMatch={isRouteMatch}>
            <Stack direction="row" alignItems="center">
              <Box>{truncatedLabel}</Box>
              <ChevronDownIcon boxSize="4" />
            </Stack>
          </Tab>
        </PopoverTrigger>
        <PopoverContent w="full">
          <PopoverBody px="8" py="4" w="full">
            <Stack direction="row" divider={<StackDivider />} spacing="4">
              <Stack maxWidth="72">
                {item.children.map((child, index) => {
                  if (child.href) {
                    if (child.isExternal) {
                      return (
                        <Stack
                          key={index + child.label}
                          as="a"
                          target="_blank"
                          rel="noreferrer"
                          href={getExternalUrl(child.href)}
                          direction="row"
                          alignItems="center"
                          textStyle="xlBody"
                          color="gray.600"
                          _hover={{ color: 'gray.800' }}
                        >
                          <Box noOfLines={1} wordBreak="break-all">
                            {child.label}
                          </Box>
                          <ExternalLinkIcon boxSize="3" />
                        </Stack>
                      );
                    }

                    return (
                      <NextLink key={index + child.label} href={child.href}>
                        <Box
                          textStyle="xlBody"
                          color="gray.600"
                          _hover={{ color: 'gray.800' }}
                          noOfLines={1}
                          wordBreak="break-all"
                        >
                          {child.label}
                        </Box>
                      </NextLink>
                    );
                  }

                  return null;
                })}
              </Stack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return null;
};

HeaderNavigationTabLink.navigationItemFragment = gql`
  fragment HeaderNavigationTabLink_NavigationItem on NavigationItem {
    label
    href
    isExternal
    children {
      label
      href
      isExternal
    }
  }
`;
