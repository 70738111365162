import { Center, ModalBody, Text } from '@chakra-ui/react';
import * as React from 'react';
import { StateResultsProvided } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';

import { Loading } from '@/imports/ui/chakra/components/Loading';

export const GlobalSearchModalNoResults =
  connectStateResults<StateResultsProvided>((props) => {
    const nbHits = Object.keys(props.allSearchResults).reduce(
      (value, key) => value + props.allSearchResults[key].nbHits,
      0
    );

    if (props.searching) {
      return null;
    }

    if (props.searchState.query?.length && !nbHits) {
      return (
        <ModalBody borderTop="1px" borderColor="gray.200">
          <Center>
            <Text color="gray.500">No Results</Text>
          </Center>
        </ModalBody>
      );
    }

    return null;
  });
