import { NotificationActivity } from 'getstream';
import * as React from 'react';

import {
  logNotificationError,
  logNotificationInfo,
} from '@/imports/logging/ClientLogger';

import { IStreamNotificationFeed } from '../StreamFeedProvider';
import { ITradewingActivity } from '../activityTypes';
import { IEnrichedActivityGroup } from '../components/ActivityGroup/types';
import {
  collectEntityIdsFromActivityGroup,
  stitchEntitiesWithActivityGroup,
} from '../components/ActivityGroup/utils';
import { useEntityFetchByIds } from './useEntityFetchByIds';

const defaultFeedInformation = {
  activityGroups: [],
  numUnread: 0,
  numUnseen: 0,
  hasMore: false,
};

type IFeedInformation = {
  activityGroups: NotificationActivity<ITradewingActivity>[];
  numUnread?: number;
  numUnseen?: number;
  hasMore: boolean;
};

type IUseNotificationFeedOptions = {
  notificationFeed: IStreamNotificationFeed;
  tenantId: string;
  notificationLimit: number;
  shouldRefetchNotificationsOnUpdate: boolean;
};

type INotificationItem = {
  enrichedActivityGroup: IEnrichedActivityGroup;
  markRead: () => Promise<void>;
};

type IUseNotificationFeedResult = {
  notificationItems: INotificationItem[];
  notificationsMetadata: Pick<IFeedInformation, 'numUnread' | 'numUnseen'>;
  loading: boolean;
  resetNotifications: (markSeen?: boolean) => void;
  newUpdates: boolean;
  paginationOptions: {
    hasNext: boolean;
    fetchMore: () => void;
  };
  clearNewUpdates: () => void;
  markNotificationsAsSeen: () => void;
};

export function useNotificationFeed(
  options: IUseNotificationFeedOptions
): IUseNotificationFeedResult {
  const {
    notificationFeed,
    tenantId,
    notificationLimit,
    shouldRefetchNotificationsOnUpdate,
  } = options;
  const [feedInformation, setFeedInformation] =
    React.useState<IFeedInformation>(defaultFeedInformation);

  const [newFeedChanges, setNewFeedChanges] = React.useState<boolean>(false);

  const [newUpdates, setNewUpdates] = React.useState<boolean>(false);

  const [streamFeedLoading, setStreamFeedLoading] =
    React.useState<boolean>(false);

  const [notificationItems, setNotificationItems] = React.useState<
    INotificationItem[]
  >([]);

  const markNotificationsAsSeen = React.useCallback(() => {
    notificationFeed
      .get({ mark_seen: true })
      .then(() => {
        setFeedInformation((prev) => ({ ...prev, numUnseen: 0 }));
      })
      .catch((err) => {
        logNotificationError(err);
      });
  }, [notificationFeed]);

  const {
    fetchNewEntities,
    entityIdsToEntities,
    loading: entityRefetchLoading,
  } = useEntityFetchByIds();

  const { activityGroups } = feedInformation;

  const resetAllNotifications = React.useCallback(
    (markSeen?: boolean) => {
      setNewUpdates(false);
      setStreamFeedLoading(true);

      notificationFeed
        .get({ limit: notificationLimit, mark_seen: markSeen })
        .then((activities) =>
          setFeedInformation({
            numUnread: activities.unread,
            numUnseen: markSeen ? 0 : activities.unseen,
            activityGroups:
              activities.results as NotificationActivity<ITradewingActivity>[],
            hasMore: !!activities.next,
          })
        )
        .catch((err) => logNotificationError(err))
        .finally(() => {
          setStreamFeedLoading(false);
        });
    },
    [notificationFeed, notificationLimit]
  );

  React.useEffect(() => {
    notificationFeed
      .subscribe(() => {
        setNewFeedChanges(true);
      })
      .then(() => {
        logNotificationInfo(`Successfully subscribed to notifications feed`);
      })
      .catch((err) => {
        logNotificationError(
          new Error(
            `An error occurred while subscribing to notifications feed ${err.message}`
          )
        );
      });
    return () => {
      notificationFeed.unsubscribe();
    };
  }, [notificationFeed]);

  React.useEffect(() => {
    if (newFeedChanges) {
      setNewFeedChanges(() => {
        if (shouldRefetchNotificationsOnUpdate) {
          resetAllNotifications();
        } else {
          setNewUpdates(true);
        }
        return false;
      });
    }
  }, [
    newFeedChanges,
    shouldRefetchNotificationsOnUpdate,
    resetAllNotifications,
  ]);

  React.useEffect(() => {
    resetAllNotifications();
  }, [resetAllNotifications]);

  React.useEffect(() => {
    const entityIds = activityGroups.reduce(
      (curr, activityGroup) => {
        const entityIds = collectEntityIdsFromActivityGroup(
          activityGroup,
          tenantId
        );
        return {
          userIds: [...new Set([...curr.userIds, ...entityIds.userIds])],
          postIds: [...new Set([...curr.postIds, ...entityIds.postIds])],
          commentIds: [
            ...new Set([...curr.commentIds, ...entityIds.commentIds]),
          ],
        };
      },
      {
        userIds: [],
        postIds: [],
        commentIds: [],
      }
    );

    fetchNewEntities({
      variables: {
        postIds: {
          postIds: entityIds.postIds,
        },
        userIds: {
          userIds: entityIds.userIds,
        },
        commentIds: { commentIds: entityIds.commentIds },
      },
    });
  }, [activityGroups, fetchNewEntities, tenantId]);

  const getNextPageNotifications = (): void => {
    setStreamFeedLoading(true);
    notificationFeed
      .get({
        limit: notificationLimit,
        id_lt:
          feedInformation.activityGroups[
            feedInformation.activityGroups.length - 1
          ].id,
      })
      .then((activities) =>
        setFeedInformation((prev) => {
          return {
            numUnseen: activities.unseen,
            numUnread: activities.unread,
            hasMore: !!activities.next,
            activityGroups: [
              ...prev.activityGroups,
              ...(activities.results as NotificationActivity<ITradewingActivity>[]),
            ],
          };
        })
      )
      .catch((err) => logNotificationError(err))
      .finally(() => setStreamFeedLoading(false));
  };

  React.useEffect(() => {
    setNotificationItems(() => {
      return activityGroups
        .map((result, index) => {
          return {
            ...result,
            markRead: async () => {
              try {
                await notificationFeed.get({ mark_read: [result.id] });
                setFeedInformation((prev) => {
                  const activityGroup = prev.activityGroups[index];
                  if (activityGroup.is_read) {
                    return prev;
                  }
                  return {
                    ...prev,
                    numUnread: prev.numUnread && prev.numUnread - 1,
                    activityGroups: prev.activityGroups.map((group, i) => {
                      return i === index ? { ...group, is_read: true } : group;
                    }),
                  };
                });
              } catch (err) {
                logNotificationError(
                  new Error(
                    `Error marking activity group ${result.id} as read with message ${err}`
                  )
                );
              }
            },
          };
        })
        .map((activityGroup) => {
          const enrichedActivityGroup = stitchEntitiesWithActivityGroup({
            activityGroup,
            tenantId,
            entityIdsToEntities,
          });
          if (!enrichedActivityGroup) {
            return null;
          }
          return {
            enrichedActivityGroup,
            markRead: activityGroup.markRead,
          };
        })
        .filter(
          (enrichedActivityGroup) => !!enrichedActivityGroup
        ) as INotificationItem[];
    });
  }, [activityGroups, entityIdsToEntities, notificationFeed, tenantId]);

  const clearNewUpdates = React.useCallback(() => {
    setNewUpdates(false);
  }, []);

  return {
    notificationsMetadata: {
      numUnread: feedInformation.numUnread,
      numUnseen: feedInformation.numUnseen,
    },
    notificationItems: notificationItems,
    loading: streamFeedLoading || entityRefetchLoading,
    paginationOptions: {
      fetchMore: getNextPageNotifications,
      hasNext: feedInformation.hasMore,
    },
    resetNotifications: resetAllNotifications,
    newUpdates,
    clearNewUpdates,
    markNotificationsAsSeen,
  };
}
