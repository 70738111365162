import { Box, Circle } from '@chakra-ui/react';
import * as React from 'react';

import { PieChartIcon } from '@/imports/ui/chakra/feather';

import {
  ETradewingActivityGroupType,
  IEnrichedActivityGroup,
} from '../../types';
import { ActorAvatar } from './ActorAvatar';
import { NotificationIcon } from './NotificationIcon';

const ICON_SIZE = 8;

export enum EMediaIcon {
  AT_MENTION = 'AT_MENTION',
  LIKE = 'LIKE',
  COMMENT = 'COMMENT',
  VOTE = 'VOTE',
}

type IActivityGroupMultiAvatarProps = {
  enrichedActivityGroup: IEnrichedActivityGroup;
};

export const ActivityGroupMultiAvatar: React.FC<
  IActivityGroupMultiAvatarProps
> = (props) => {
  const { enrichedActivityGroup } = props;

  return (
    <Box
      position="relative"
      pr={ICON_SIZE / 2}
      pb={!!enrichedActivityGroup.secondActor ? 2 : 0}
    >
      {enrichedActivityGroup.activityGroupType ===
      ETradewingActivityGroupType.POLL_VOTE ? (
        !enrichedActivityGroup.secondActor ? (
          <Box boxSize={16} position="relative">
            <Circle
              backgroundColor="primary.500"
              size={16}
              position="absolute"
              boxShadow="base"
            >
              <PieChartIcon boxSize={10} margin="0 auto" strokeWidth="1.5" />
            </Circle>
          </Box>
        ) : (
          <Box boxSize={16} position="relative">
            <Circle
              backgroundColor="primary.500"
              size={10}
              position="absolute"
              top={0}
              left={0}
              boxShadow="base"
            >
              <PieChartIcon boxSize={8} margin="0 auto" strokeWidth="1.5" />
            </Circle>
            <Circle
              backgroundColor="primary.500"
              size={10}
              position="absolute"
              bottom={0}
              right={0}
              boxShadow="base"
            >
              <PieChartIcon boxSize={8} margin="0 auto" strokeWidth="1.5" />
            </Circle>
          </Box>
        )
      ) : !enrichedActivityGroup.secondActor ? (
        <ActorAvatar
          actor={enrichedActivityGroup.firstActor}
          boxShadow="base"
          boxSize={16}
        />
      ) : (
        <Box boxSize={16} position="relative">
          <ActorAvatar
            actor={enrichedActivityGroup.firstActor}
            boxShadow="base"
            boxSize={10}
            top={0}
            left={0}
            position="absolute"
          />
          <ActorAvatar
            actor={enrichedActivityGroup.secondActor}
            bottom={0}
            right={0}
            boxShadow="base"
            position="absolute"
            boxSize={10}
          />
        </Box>
      )}
      <Circle
        backgroundColor="white"
        size={ICON_SIZE}
        bottom={0}
        right={0}
        position="absolute"
        boxShadow="base"
      >
        <NotificationIcon enrichedActivityGroup={enrichedActivityGroup} />
      </Circle>
    </Box>
  );
};
