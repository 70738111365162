import { Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';

import { IPostLikeData } from '../../types';
import { getPostTypeReadableName, getTextContentForPost } from '../../utils';

type IPostLikeTextContent = {
  data: IPostLikeData;
} & TextProps;

export const PostLikeTextContent: React.FC<IPostLikeTextContent> = (props) => {
  const { data, ...rest } = props;

  const content = getTextContentForPost(data.post);

  return (
    <Text {...rest}>
      <Semibold>{data.firstActor.displayName}</Semibold>{' '}
      {data.numUniqueActors > 1
        ? `and ${data.numUniqueActors - 1} other${
            data.numUniqueActors > 2 ? 's' : ''
          } `
        : ''}
      liked your {getPostTypeReadableName(data.postType)}
      <Semibold>{`${content ? `: ${content}` : ''}`}</Semibold>
    </Text>
  );
};
