import { AlertProps, Button, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import random from 'lodash/random';
import * as React from 'react';
import { useInterval } from 'usehooks-ts';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';

import { useNewClientIsAvailable } from '../hooks/useNewClientIsAvailable';

dayjs.extend(relativeTime);

const TIMEOUT_BASE_HRS = 1;
const JITTER_MINS = 45;

const NewClientAlert: React.FC<AlertProps> = (props) => {
  const expirationDate = React.useRef<Date>(
    dayjs()
      .add(TIMEOUT_BASE_HRS, 'hour')
      .add(random(JITTER_MINS), 'minute')
      .toDate()
  );

  const [fromNow, setFromNow] = React.useState(() => {
    return dayjs().to(expirationDate.current);
  });

  useInterval(() => {
    if (dayjs().isAfter(expirationDate.current)) {
      window.location.reload();
    } else {
      setFromNow(dayjs().to(expirationDate.current));
    }
  }, 1000);

  return (
    <Stack
      p="4"
      spacing="4"
      direction={{ base: 'column', md: 'row' }}
      shadow="base"
      bg="white"
      {...props}
    >
      <Text flex="1">
        <Semibold>A new version of the site is available.</Semibold> You can
        manually refresh the page or let it automatically refresh {fromNow}.
      </Text>

      <Button
        variant="outline"
        flexShrink="0"
        size="xs"
        colorScheme="gray"
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh Page
      </Button>
    </Stack>
  );
};

export const NewClientAvailable: React.FC<AlertProps> = (props) => {
  const { newClientIsAvailable } = useNewClientIsAvailable();

  if (!newClientIsAvailable) {
    return null;
  }

  return <NewClientAlert {...props} />;
};
