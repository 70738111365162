import { Icon } from '@chakra-ui/react';
import * as React from 'react';

import {
  AtSignIcon,
  CheckSquareIcon,
  MessageSquareIcon,
  ThumbsUpIcon,
} from '@/imports/ui/chakra/feather';

import {
  ETradewingActivityGroupType,
  IEnrichedActivityGroup,
} from '../../types';

type INotificationIconProps = {
  enrichedActivityGroup: IEnrichedActivityGroup;
};

const icons = new Map<ETradewingActivityGroupType, React.FC>([
  [ETradewingActivityGroupType.POST_LIKE, ThumbsUpIcon],
  [ETradewingActivityGroupType.COMMENT_LIKE, ThumbsUpIcon],
  [ETradewingActivityGroupType.PARENT_POST_COMMENT, MessageSquareIcon],
  [ETradewingActivityGroupType.PARENT_COMMENT_COMMENT, MessageSquareIcon],
  [ETradewingActivityGroupType.SIBLING_COMMENT, MessageSquareIcon],
  [ETradewingActivityGroupType.QUESTION_ANSWER, MessageSquareIcon],
  [ETradewingActivityGroupType.COMMENT_AT_MENTION, AtSignIcon],
  [ETradewingActivityGroupType.POST_AT_MENTION, AtSignIcon],
  [ETradewingActivityGroupType.POLL_VOTE, CheckSquareIcon],
]);

export const NotificationIcon: React.FC<INotificationIconProps> = (props) => {
  const { enrichedActivityGroup } = props;

  return (
    <Icon
      as={icons.get(enrichedActivityGroup.activityGroupType)}
      boxSize={4}
      margin="0 auto"
      strokeWidth="1.5"
    />
  );
};
