import { TextProps } from '@chakra-ui/react';
import * as React from 'react';

import { logNotificationWarning } from '@/imports/logging/ClientLogger';

import {
  ETradewingActivityGroupType,
  IEnrichedActivityGroup,
} from '../../types';
import { CommentAtMentionTextContent } from './CommentAtMention';
import { CommentLikeTextContent } from './CommentLike';
import { ParentCommentCommentTextContent } from './ParentCommentComment';
import { ParentPostCommentTextContent } from './ParentPostComment';
import { PollVoteTextContent } from './PollVote';
import { PostAtMentionTextContent } from './PostAtMention';
import { PostLikeTextContent } from './PostLikeTextContent';
import { QuestionAnswerTextContent } from './QuestionAnswer';
import { SiblingCommentTextContent } from './SiblingComment';

type IActivityGroupTextContentProps = {
  enrichedActivityGroup: IEnrichedActivityGroup;
} & TextProps;

export const ActivityGroupTextContent: React.FC<
  IActivityGroupTextContentProps
> = (props) => {
  const { enrichedActivityGroup, ...rest } = props;

  switch (enrichedActivityGroup.activityGroupType) {
    case ETradewingActivityGroupType.POST_LIKE:
      return <PostLikeTextContent data={enrichedActivityGroup} {...rest} />;
    case ETradewingActivityGroupType.PARENT_POST_COMMENT:
      return (
        <ParentPostCommentTextContent data={enrichedActivityGroup} {...rest} />
      );
    case ETradewingActivityGroupType.PARENT_COMMENT_COMMENT:
      return (
        <ParentCommentCommentTextContent
          data={enrichedActivityGroup}
          {...rest}
        />
      );
    case ETradewingActivityGroupType.SIBLING_COMMENT:
      return (
        <SiblingCommentTextContent data={enrichedActivityGroup} {...rest} />
      );
    case ETradewingActivityGroupType.COMMENT_AT_MENTION:
      return (
        <CommentAtMentionTextContent data={enrichedActivityGroup} {...rest} />
      );
    case ETradewingActivityGroupType.POST_AT_MENTION:
      return (
        <PostAtMentionTextContent data={enrichedActivityGroup} {...rest} />
      );
    case ETradewingActivityGroupType.QUESTION_ANSWER:
      return (
        <QuestionAnswerTextContent data={enrichedActivityGroup} {...rest} />
      );
    case ETradewingActivityGroupType.COMMENT_LIKE:
      return <CommentLikeTextContent data={enrichedActivityGroup} {...rest} />;
    case ETradewingActivityGroupType.POLL_VOTE:
      return <PollVoteTextContent data={enrichedActivityGroup} {...rest} />;

    default:
      logNotificationWarning(
        `Could not get valid activity group type from activity group ${JSON.stringify(
          enrichedActivityGroup
        )}`
      );
      return null;
  }
};
