import {
  Box,
  Button,
  Center,
  Circle,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import FocusLock from 'react-focus-lock';

import { useCurrent } from '@/hooks/useCurrent';
import { Loading } from '@/imports/ui/chakra/components/Loading';
import { BellIcon } from '@/imports/ui/chakra/feather';
import { useIsMobile } from '@/imports/ui/responsive/Responsive';

import {
  IStreamNotificationFeed,
  useStreamFeedContext,
} from '../../StreamFeedProvider';
import { useNotificationFeed } from '../../hooks/useNotificationFeed';
import { InAppNotificationDropdownItem } from './InAppNotificationDropdownItem';

const STREAM_FEED_LIMIT = 8;

export const InAppNotificationDropdownContent: React.FC<{
  notificationFeed: IStreamNotificationFeed;
  tenantId: string;
}> = ({ notificationFeed, tenantId }) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const {
    isOpen,
    onOpen: defaultOnOpen,
    onClose: defaultOnClose,
  } = useDisclosure();

  const {
    loading,
    notificationItems,
    notificationsMetadata,
    resetNotifications,
    paginationOptions,
    newUpdates,
    clearNewUpdates,
    markNotificationsAsSeen,
  } = useNotificationFeed({
    tenantId,
    notificationFeed,
    notificationLimit: STREAM_FEED_LIMIT,
    shouldRefetchNotificationsOnUpdate: !isOpen,
  });

  const onClose = React.useCallback(() => {
    defaultOnClose();
    if (newUpdates) {
      clearNewUpdates();
      resetNotifications();
    }
  }, [defaultOnClose, clearNewUpdates, resetNotifications, newUpdates]);

  const onOpen = React.useCallback(() => {
    if (isMobile) {
      router.push('/notifications');
    } else {
      defaultOnOpen();
      markNotificationsAsSeen();
    }
  }, [defaultOnOpen, markNotificationsAsSeen, isMobile, router]);

  return (
    <Box>
      <Popover isOpen={isOpen} onClose={onClose} isLazy placement="bottom-end">
        <Box position="relative">
          <PopoverTrigger>
            <IconButton
              onClick={onOpen}
              aria-label="Notifications"
              icon={<BellIcon />}
              variant="outline"
              colorScheme="gray"
              isRound
            />
          </PopoverTrigger>
          {notificationsMetadata.numUnseen &&
          notificationsMetadata.numUnseen > 0 ? (
            <Circle
              position="absolute"
              top={-1}
              right={-1}
              size={4}
              backgroundColor="red.600"
            >
              <Text color="white" fontSize={10} lineHeight="1em">
                {notificationsMetadata.numUnseen > 9
                  ? '9+'
                  : notificationsMetadata.numUnseen}
              </Text>
            </Circle>
          ) : null}
        </Box>
        <PopoverContent
          maxWidth="440px"
          maxHeight="80vh"
          overflowY="auto"
          borderRadius="xl"
        >
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverBody>
              <Text textStyle="xlBodyStrong" p="2">
                Notifications
              </Text>
              {newUpdates && (
                <Box
                  position="absolute"
                  width="100%"
                  display="flex"
                  placeContent="center"
                >
                  <Button
                    borderRadius="full"
                    mt="8"
                    onClick={() => resetNotifications(true)}
                  >
                    See New Notifications
                  </Button>
                </Box>
              )}
              {notificationItems.length > 0 ? (
                <Stack>
                  {notificationItems.map((notificationItem) => {
                    return (
                      <InAppNotificationDropdownItem
                        key={notificationItem.enrichedActivityGroup.id}
                        enrichedActivityGroup={
                          notificationItem.enrichedActivityGroup
                        }
                        markRead={notificationItem.markRead}
                      />
                    );
                  })}
                </Stack>
              ) : loading ? (
                <Loading />
              ) : (
                <Box py="4" px="16" role="menuitem">
                  There {`aren't`} any notifications for you yet.
                </Box>
              )}
              {loading && notificationItems.length > 0 && <Loading />}

              {paginationOptions.hasNext && !loading && (
                <Center p="4">
                  <Link as={NextLink} href={'/notifications'}>
                    View More
                  </Link>
                </Center>
              )}
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const InAppNotificationDropdown: React.FC = () => {
  const streamFeedState = useStreamFeedContext();
  const current = useCurrent();
  const router = useRouter();
  const notificationsActive = router.pathname === '/notifications';

  return notificationsActive ? (
    <IconButton
      aria-label="Notifications"
      icon={<BellIcon />}
      variant="outline"
      colorScheme="gray"
      isRound
      disabled
    />
  ) : !!streamFeedState?.notificationFeed && !!current.tenant?._id ? (
    <InAppNotificationDropdownContent
      notificationFeed={streamFeedState.notificationFeed}
      tenantId={current.tenant._id}
    />
  ) : null;
};
