import {
  Box,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import { SearchHelpers } from '@/client/legacy-shared-modules/search/helpers';
import { IEventSearch } from '@/client/legacy-shared-modules/search/search-types';
import { CustomHighlight } from '@/components/CustomHighlight';
import { CustomSnippet } from '@/components/CustomSnippet';
import { ImageFallback } from '@/imports/ui/chakra/components/ImageFallback';

export const Event: React.FC<{
  hit: IEventSearch;
}> = ({ hit }) => {
  return (
    <LinkBox
      as={Stack}
      direction="row"
      spacing="2"
      alignItems="center"
      p="2"
      bg="white"
      _hover={{ bg: 'gray.100' }}
      borderRadius="xl"
      cursor="pointer"
    >
      <Image
        boxSize="8"
        objectFit="cover"
        src={hit.bannerImageUrl ?? undefined}
        alt=""
        borderRadius="base"
        fallback={<ImageFallback boxSize="8" borderRadius="base" />}
      />

      <Box>
        <LinkOverlay as={NextLink} href={SearchHelpers.getEventUrl(hit)}>
          <Text noOfLines={1} textStyle="bodyStrong">
            <CustomHighlight attribute="name" hit={hit} />
          </Text>
        </LinkOverlay>

        <Text noOfLines={1} textStyle="compact" color="gray.600">
          <CustomSnippet attribute="descriptionPlainText" hit={hit} />
        </Text>

        <Text noOfLines={1} textStyle="compact" color="gray.600">
          <CustomSnippet attribute="shortDescription" hit={hit} />
        </Text>
      </Box>
    </LinkBox>
  );
};
