import {
  Box,
  BoxProps,
  Button,
  Image,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import { GlobalSearch } from '@/components/GlobalSearch';
import { EPromptState, LogInSignUpModal } from '@/components/LogInSignUp';
import { EAccountCreationPermission } from '@/graphql-types/globalTypes';
import { useCurrent } from '@/hooks/useCurrent';
import { useMemberManagement } from '@/hooks/useMemberManagement';
import { usePermissions } from '@/hooks/usePermissions';
import { AccountMenu } from '@/imports/ui/components/community-frame/AccountMenu';
import { ChatNavLinkIconDisplay } from '@/imports/ui/components/community-frame/chat-nav/ChatNavLinkIconDisplay';
import { InAppNotificationDropdown } from '@/imports/ui/notifications/components';

import { HeaderNavigationMenu, HeaderNavigationTabs } from './HeaderNavigation';

export const Header: React.FC<{ isContained?: boolean } & BoxProps> = ({
  isContained = true,
  ...props
}) => {
  const memberManagement = useMemberManagement();
  const loginModal = useDisclosure();
  const current = useCurrent();
  const [loginModalPromptState, setLoginModalPromptState] =
    React.useState<EPromptState>(EPromptState.LOGIN_FORM);
  const permissions = usePermissions();

  return (
    <Box bg="white" shadow="base" as="header" {...props}>
      <Stack
        direction="row"
        maxW={isContained ? 'container.xl' : undefined}
        mx="auto"
        alignItems="stretch"
        justify="space-between"
        px="4"
        spacing="4"
      >
        <Box py="4" flex="1" display="flex" alignItems="center">
          {current.tenant?.logo?.imageProxy && (
            <NextLink href="/">
              <Image
                display={{ base: 'none', xl: 'block' }}
                maxH="10"
                maxW="52"
                w="auto"
                alt="association logo"
                src={current.tenant.logo.imageProxy}
                cursor="pointer"
              />
            </NextLink>
          )}
          {current.tenant?.logoIcon?.imageProxy && (
            <NextLink href="/">
              <Image
                display={{ xl: 'none' }}
                maxH="10"
                maxW="10"
                w="auto"
                alt="association logo"
                src={current.tenant.logoIcon.imageProxy}
                cursor="pointer"
              />
            </NextLink>
          )}
        </Box>

        <HeaderNavigationTabs display={{ base: 'none', lg: 'flex' }} />

        <Stack
          direction="row"
          spacing="4"
          py="4"
          flex="1"
          justifyContent="flex-end"
        >
          {permissions.isLoading ||
          permissions.isLoading ? null : permissions.isAuthenticated ? (
            <>
              {permissions.canAccessCommunityAndAuthenticated && (
                <GlobalSearch />
              )}

              <InAppNotificationDropdown />

              {permissions.canAccessDmPageAndAuthenticated && (
                <ChatNavLinkIconDisplay />
              )}

              <AccountMenu />
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setLoginModalPromptState(EPromptState.LOGIN_FORM);
                  loginModal.onOpen();
                }}
                colorScheme="gray"
              >
                Log in
              </Button>

              {memberManagement.isActive ? (
                <NextLink href="/memberships">
                  <Button colorScheme="primary">Join Now</Button>
                </NextLink>
              ) : current.tenant?.accountCreationPermission ===
                EAccountCreationPermission.ANYONE ? (
                <Button
                  onClick={() => {
                    setLoginModalPromptState(EPromptState.SIGNUP_FORM);
                    loginModal.onOpen();
                  }}
                  colorScheme="primary"
                >
                  Sign up
                </Button>
              ) : null}

              <LogInSignUpModal
                initialPromptState={loginModalPromptState}
                isOpen={loginModal.isOpen}
                onClose={loginModal.onClose}
              />
            </>
          )}

          <HeaderNavigationMenu
            display={{ lg: 'none' }}
            aria-label="navigation menu"
          />
        </Stack>
      </Stack>
    </Box>
  );
};
