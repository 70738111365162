import { useApolloClient } from '@apollo/client';
import { Box, Button, HStack, MenuItem, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useRouter } from 'next/router';
import * as React from 'react';

import { ActivityGroupTextContent } from '../ActivityGroup';
import { ActivityGroupMultiAvatar } from '../ActivityGroup/components/ActivityGroupMultiAvatar';
import { IEnrichedActivityGroup } from '../ActivityGroup/types';
import { getClickHandlerForActivityGroup } from '../ActivityGroup/utils';

dayjs.extend(relativeTime);
dayjs.extend(utc);

type IInAppNotificationDropdownItemProps = {
  enrichedActivityGroup: IEnrichedActivityGroup;
  markRead: () => Promise<void>;
};

export const InAppNotificationDropdownItem: React.FC<
  IInAppNotificationDropdownItemProps
> = (props) => {
  const { enrichedActivityGroup, markRead } = props;
  const router = useRouter();
  const apolloClient = useApolloClient();

  const onClickUrl = getClickHandlerForActivityGroup(
    enrichedActivityGroup,
    apolloClient.cache,
    router
  );

  const onClick = async (): Promise<void> => {
    await markRead();

    onClickUrl();
  };

  const updatedAt = dayjs.utc(enrichedActivityGroup.updated_at);

  return (
    <Box
      as="button"
      textAlign="left"
      px="4"
      py="2"
      backgroundColor={enrichedActivityGroup.is_read ? 'white' : 'primary.50'}
      onClick={onClick}
      w="full"
      borderRadius="lg"
    >
      <HStack>
        <ActivityGroupMultiAvatar
          enrichedActivityGroup={enrichedActivityGroup}
        />
        <Box ml="4">
          <Box>
            <ActivityGroupTextContent
              noOfLines={2}
              enrichedActivityGroup={enrichedActivityGroup}
            />
          </Box>
          <Box>
            <Text textStyle="detail">
              <time dateTime={updatedAt.toISOString()}>
                {updatedAt.fromNow()}
              </time>
            </Text>
          </Box>
        </Box>
      </HStack>
      {props.children}
    </Box>
  );
};
