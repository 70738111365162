import { Box, Circle, IconButton, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

import { MessageCircleIcon } from '@/imports/ui/chakra/feather';
import { useMessages } from '@/modules/messaging';

type IChatNavLinkIconDisplayProps = {};

export const ChatNavLinkIconDisplay: React.FC<
  IChatNavLinkIconDisplayProps
> = () => {
  const router = useRouter();
  const { totalUnreadCount } = useMessages();

  return (
    <Box position="relative">
      <IconButton
        onClick={() => router.push('/messages')}
        aria-label="Chat"
        icon={<MessageCircleIcon />}
        variant="outline"
        colorScheme="gray"
        isRound
      />
      {totalUnreadCount > 0 ? (
        <Circle
          position="absolute"
          top={-1}
          right={-1}
          size={4}
          backgroundColor="red.600"
        >
          <Text color="white" fontSize={10} lineHeight="1em">
            {totalUnreadCount > 9 ? '9+' : totalUnreadCount}
          </Text>
        </Circle>
      ) : null}
    </Box>
  );
};
