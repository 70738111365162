import {
  EEventSearchType,
  EPostSearchType,
  IEventSearch,
  IFileSearch,
  IMemberSearch,
  IOrganizationSearch,
  IPostSearch,
} from './search-types';

export const SearchHelpers = {
  getPostUrl(hit: IPostSearch): string {
    switch (hit.postType) {
      case EPostSearchType.BLOG:
        return `/blog/${hit.blog._id}`;
      case EPostSearchType.POLL:
        return `/poll/${hit.poll._id}`;
      case EPostSearchType.POST:
        return `/post/${hit.objectID}`;
      case EPostSearchType.QUESTION:
        return `/question/${hit.question._id}`;
      case EPostSearchType.ANSWER:
        return `/question/${hit.answer.question._id}`;
      case EPostSearchType.COMMENT:
        switch (hit.post.type) {
          case EPostSearchType.BLOG:
            return `/blog/${hit.post.blog._id}?comment=${hit.objectID}`;
          case EPostSearchType.POLL:
            return `/poll/${hit.post.poll._id}?comment=${hit.objectID}`;
          case EPostSearchType.POST:
            return `/post/${hit.post._id}?comment=${hit.objectID}`;
          case EPostSearchType.QUESTION:
            return `/question/${hit.post.question._id}?comment=${hit.objectID}`;
          case EPostSearchType.ANSWER:
            return `/question/${hit.post.answer.questionId}?comment=${hit.objectID}`;
        }
    }
  },
  getMemberUrl(hit: IMemberSearch): string {
    return hit.userId ? `/members/${hit.userId}` : `/members/${hit.objectID}`;
  },
  getEventUrl(hit: IEventSearch): string {
    return {
      [EEventSearchType.CONFERENCE]: `/events/conferences/${hit.objectID}`,
      [EEventSearchType.SIMPLE]: `/events/${hit.objectID}`,
    }[hit.eventType];
  },
  getFileUrl(hit: IFileSearch): string {
    // Existing files uploaded to algolia do not have the group id appended, but do use the group scope.
    // Getting the id off the scope is a bit hacky though, so using it only as a backup
    const groupId = hit.groupId
      ? hit.groupId
      : hit.scope
          .filter((item) => item.startsWith('group_'))
          .at(0)
          ?.split('_')
          .at(-1);
    if (groupId)
      return `/community/groups/${groupId}/resources?versionFileId=${hit.objectID}`;
    //TODO: when we implement comment navigation, this can point to it using the hit.originalCommentId
    return `/post/${hit.originalPostId}`;
  },
};
