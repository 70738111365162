import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import * as React from 'react';
import { SearchBoxProvided } from 'react-instantsearch-core';
import { connectSearchBox } from 'react-instantsearch-dom';

import { SearchIcon } from '@/imports/ui/chakra/feather';
import { useDebouncedValue } from '@/imports/ui/hooks/useDebouncedValue';

export const SearchBox = connectSearchBox<
  SearchBoxProvided & { placeholder?: string }
>(({ refine, placeholder }) => {
  const [value, setValue] = React.useState('');
  const debouncedValue = useDebouncedValue(value, 500);

  React.useEffect(() => refine(debouncedValue), [refine, debouncedValue]);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.600" />
      </InputLeftElement>
      <Input
        variant="filled"
        borderRadius="full"
        placeholder={placeholder}
        type="search"
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
    </InputGroup>
  );
});
