import { gql, useQuery } from '@apollo/client';
import { Box, BoxProps, Button, Text, useDisclosure } from '@chakra-ui/react';
import { convertFromRaw } from 'draft-js';
import * as React from 'react';

import { CodeOfConductModal } from '@/components/CodeOfConductModal';
import { useCurrent } from '@/hooks/useCurrent';

export const CodeOfConductBanner: React.FC<BoxProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const current = useCurrent();

  React.useEffect(() => {
    const delay = setTimeout(() => {
      if (current.tenant?.obnoxiousCodeOfConduct === true) {
        onOpen();
      }
    }, 500);

    return () => clearTimeout(delay);
  }, [current.tenant?.obnoxiousCodeOfConduct, onOpen]);

  if (!current.tenant) {
    return null;
  }

  if (!current.user) {
    return null;
  }

  if (!!current.user.acceptedCodeOfConduct) {
    return null;
  }

  if (!current.tenant.codeOfConduct) {
    return null;
  }

  if (convertFromRaw(current.tenant.codeOfConduct).getPlainText() === '') {
    return null;
  }

  if (!current.user.onboardingFlowState.isSkippedOrCompleted) {
    return null;
  }

  return (
    <Box backgroundColor="white" padding="2" shadow="base" {...props}>
      <Text align="center">
        Please read and accept the{' '}
        <Button variant="link" colorScheme="black" onClick={onOpen}>
          Code of Conduct
        </Button>
      </Text>
      <CodeOfConductModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};
