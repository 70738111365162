import * as React from 'react';

export const useElementHeight = () => {
  const ref = React.useRef(null);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((resizeObserverentries) => {
      // Some browsers to do support borderBoxSize, so we fall back to the contentRect in the event that the former is undefined.
      // This will not account for padding and borders, which may lead to less correct behavior, but it's better than erroring out in the event
      // that we cannot get the borderBoxSize
      const height = !!resizeObserverentries[0].borderBoxSize
        ? resizeObserverentries[0].borderBoxSize[0].blockSize
        : resizeObserverentries[0].contentRect.height;
      setHeight(height);
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { ref, height };
};
