import { Avatar, AvatarProps } from '@chakra-ui/avatar';
import * as React from 'react';

import { useEntityFetchByIds_query_usersByIds_results } from '@/imports/ui/notifications/hooks/graphql-types/useEntityFetchByIds_query';

type IActorAvatarProps = {
  actor: useEntityFetchByIds_query_usersByIds_results;
} & AvatarProps;

export const ActorAvatar: React.FC<IActorAvatarProps> = (props) => {
  const { actor, ...rest } = props;
  return (
    <Avatar
      src={actor.proxyProfilePictureUrl || undefined}
      name={actor.displayName}
      {...rest}
    />
  );
};
