import { gql } from '@apollo/client';

export const RawDraftContentStateFragmentAST = gql`
  fragment RawDraftContentStateFragment on DraftJS_ContentState {
    blocks {
      key
      text
      type
      depth
      inlineStyleRanges {
        offset
        length
        style
      }
      entityRanges {
        offset
        length
        key
      }
    }
    entityMap {
      type
      mutability
      data {
        ... on DraftJS_ContentState_Entity_Data_LinkData {
          explicit
          url
          targetOption
          hideLinkPreview
        }
        ... on DraftJS_ContentState_Entity_Data_MentionData {
          mention {
            userId
            user {
              displayName
            }
            userCanView
          }
        }
        ... on DraftJS_ContentState_Entity_Data_ImageData {
          src
          alt
          height
          width
          alignment
        }
      }
    }
  }
`;
