import { Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

import { Semibold } from '@/imports/ui/chakra/components/Semibold';

import { IPostAtMentionData } from '../../types';
import { getPostTypeReadableName, getTextContentForPost } from '../../utils';

type IPostAtMentionTextContentProps = {
  data: IPostAtMentionData;
} & TextProps;

export const PostAtMentionTextContent: React.FC<
  IPostAtMentionTextContentProps
> = (props) => {
  const { data, ...rest } = props;
  const content = getTextContentForPost(data.post);

  return (
    <Text {...rest}>
      <Semibold>{data.firstActor.displayName}</Semibold>{' '}
      {data.numUniquePosters > 1
        ? `and ${data.numUniquePosters - 1} other${
            data.numUniquePosters > 2 ? 's' : ''
          } `
        : ''}
      mentioned you in {getPostTypeReadableName(data.postType)}
      <Semibold>{`${content ? `: ${content}` : ''}`}</Semibold>
    </Text>
  );
};
