import {
  Avatar,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import { SearchHelpers } from '@/client/legacy-shared-modules/search/helpers';
import {
  EPostSearchType,
  IPostSearch,
  IPostSearch_Comment,
} from '@/client/legacy-shared-modules/search/search-types';
import { CustomHighlight } from '@/components/CustomHighlight';
import { CustomSnippet } from '@/components/CustomSnippet';

const ACTION = {
  [EPostSearchType.BLOG]: 'posted an article',
  [EPostSearchType.POLL]: 'posted a poll',
  [EPostSearchType.POST]: 'posted',
  [EPostSearchType.COMMENT]: 'replied',
  [EPostSearchType.QUESTION]: 'asked a question',
  [EPostSearchType.ANSWER]: 'posted an answer',
};

const TITLE_ATTRIBUTE = {
  [EPostSearchType.BLOG]: 'blog.title',
  [EPostSearchType.POLL]: 'poll.title',
  [EPostSearchType.POST]: 'title',
  [EPostSearchType.QUESTION]: 'question.title',
};

const getCommentTitle = (hit: IPostSearch_Comment): string => {
  switch (hit.post.type) {
    case EPostSearchType.BLOG:
      return hit.post.blog.title;
    case EPostSearchType.POLL:
      return hit.post.poll.title;
    case EPostSearchType.POST:
      return hit.post.title;
    case EPostSearchType.ANSWER:
      return hit.post.answer.body;
    case EPostSearchType.QUESTION:
      return hit.post.question.title;
    default:
      return '';
  }
};

const BODY_ATTRIBUTE = {
  [EPostSearchType.BLOG]: 'blog.content',
  [EPostSearchType.POLL]: 'poll.description',
  [EPostSearchType.POST]: 'bodyPlainText',
  [EPostSearchType.COMMENT]: 'bodyPlainText',
  [EPostSearchType.QUESTION]: 'question.body',
  [EPostSearchType.ANSWER]: 'answer.body',
};

export const Post: React.FC<{
  hit: IPostSearch;
}> = ({ hit }) => {
  return (
    <LinkBox
      as={Stack}
      spacing="1"
      p="2"
      bg="white"
      _hover={{ bg: 'gray.100' }}
      borderRadius="xl"
      cursor="pointer"
    >
      <Stack direction="row" alignItems="center">
        <Avatar
          size="xs"
          src={hit.author.profilePictureUrl || undefined}
          name={`${hit.author.firstName} ${hit.author.lastName}`}
        />

        <Text textStyle="compact" color="gray.600">
          <Link as={NextLink} href={`/members/${hit.author._id}`}>
            <CustomHighlight attribute="author.firstName" hit={hit} />{' '}
            <CustomHighlight attribute="author.lastName" hit={hit} />
          </Link>{' '}
          {ACTION[hit.postType]} in{' '}
          {hit.group && (
            <Link
              as={NextLink}
              href={`/community/groups/${hit.group._id}/activity`}
            >
              {hit.group.name}
            </Link>
          )}
          {hit.organization && (
            <Link as={NextLink} href={`/companies/${hit.organization._id}`}>
              {hit.organization.name}
            </Link>
          )}
        </Text>
      </Stack>

      <Stack spacing="0">
        <LinkOverlay as={NextLink} href={SearchHelpers.getPostUrl(hit)}>
          <Text noOfLines={1} textStyle="bodyStrong">
            {hit.postType === EPostSearchType.COMMENT ? (
              getCommentTitle(hit)
            ) : hit.postType === EPostSearchType.ANSWER ? (
              hit.answer.question.title
            ) : (
              <CustomHighlight
                attribute={TITLE_ATTRIBUTE[hit.postType]}
                hit={hit}
              />
            )}
          </Text>
        </LinkOverlay>

        <Text noOfLines={2} textStyle="compact" color="gray.600">
          <CustomSnippet attribute={BODY_ATTRIBUTE[hit.postType]} hit={hit} />
        </Text>
      </Stack>
    </LinkBox>
  );
};
