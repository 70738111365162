import {
  Avatar,
  Box,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import { SearchHelpers } from '@/client/legacy-shared-modules/search/helpers';
import { IMemberSearch } from '@/client/legacy-shared-modules/search/search-types';
import { CustomHighlight } from '@/components/CustomHighlight';
import { MemberIndexViewableField } from '@/components/GlobalSearch/types';

export const User: React.FC<{
  hit: IMemberSearch;
  memberIndexViewableField: string;
}> = ({ hit, memberIndexViewableField }) => {
  const properties = hit[memberIndexViewableField as MemberIndexViewableField];

  return (
    <LinkBox
      as={Stack}
      direction="row"
      spacing="2"
      alignItems="center"
      p="2"
      bg="white"
      _hover={{ bg: 'gray.100' }}
      borderRadius="xl"
      cursor="pointer"
    >
      <Avatar
        size="sm"
        name={hit.displayName}
        src={hit.profilePictureUrl || undefined}
      />

      <Box>
        <LinkOverlay as={NextLink} href={SearchHelpers.getMemberUrl(hit)}>
          <Text noOfLines={1} textStyle="bodyStrong">
            <CustomHighlight attribute="displayName" hit={hit} />
            {properties?.nameTitle && `, `}
            {properties?.nameTitle && properties.nameTitle}
          </Text>
        </LinkOverlay>

        {/* TODO: we want to highlight these but i can't figure out how to make it work */}
        {(properties?.jobTitle || properties?.company) && (
          <Text noOfLines={1} textStyle="compact" color="gray.600">
            {properties.jobTitle && properties.jobTitle}
            {properties.jobTitle && properties.company && ' at '}
            {properties.company && properties.company}
          </Text>
        )}
      </Box>
    </LinkBox>
  );
};
