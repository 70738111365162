import { EModItemResolutionState } from '../moderation-item/moderationType';
import { EUserAccountStatus } from '../user/userEnums';

export enum AlgoliaERole {
  VIEW_ADMIN_SITE = 'VIEW_ADMIN_SITE',
  MODERATE = 'MODERATE',
  ADMIN = 'ADMIN',
  // Admin of all admins. Has all permissions.
  MORGOTH = 'MORGOTH',
  SPONSOR_ADMIN = 'SPONSOR_ADMIN',
}

export enum EPostSearchType {
  POST = 'POST',
  BLOG = 'BLOG',
  POLL = 'POLL',
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
  COMMENT = 'COMMENT',
}

export type IAutoCompleteSearch =
  | IPostSearch
  | IMemberSearch
  | IOrganizationSearch
  | IEventSearch
  | IFileSearch;

export type IPostSearch =
  | IPostSearch_Post
  | IPostSearch_Blog
  | IPostSearch_Poll
  | IPostSearch_Comment
  | IPostSearch_Question
  | IPostSearch_Answer;

type IPostSearch_Shared = {
  scope: string[];
  objectID: string;
  datePublished: Date;
  reactionCount: number;
  author: {
    _id: string;
    firstName: string;
    lastName: string;
    profilePictureUrl: string | null;
  };
  numComments: number;
  moderationState?: EModItemResolutionState;
  group?: { _id: string; name: string };
  organization?: { _id: string; name: string };
};

export type IPostSearch_Post = IPostSearch_Shared & {
  title: string;
  bodyPlainText: string;
  postType: EPostSearchType.POST;
};

type IPostSearch_Comment_Post = IPostSearch_Shared & {
  post: { _id: string; title: string; type: EPostSearchType.POST };
  bodyPlainText: string;
  postType: EPostSearchType.COMMENT;
};

type IPostSearch_Comment_Blog = IPostSearch_Shared & {
  post: {
    _id: string;
    blog: { _id: string; title: string };
    type: EPostSearchType.BLOG;
  };
  bodyPlainText: string;
  postType: EPostSearchType.COMMENT;
};

type IPostSearch_Comment_Poll = IPostSearch_Shared & {
  post: {
    _id: string;
    poll: { _id: string; title: string };
    type: EPostSearchType.POLL;
  };
  bodyPlainText: string;
  postType: EPostSearchType.COMMENT;
};

type IPostSearch_Comment_Question = IPostSearch_Shared & {
  post: {
    _id: string;
    question: { _id: string; title: string };
    type: EPostSearchType.QUESTION;
  };
  bodyPlainText: string;
  postType: EPostSearchType.COMMENT;
};

type IPostSearch_Comment_Answer = IPostSearch_Shared & {
  post: {
    _id: string;
    answer: { _id: string; body: string; questionId: string };
    type: EPostSearchType.ANSWER;
  };
  bodyPlainText: string;
  postType: EPostSearchType.COMMENT;
};

export type IPostSearch_Comment =
  | IPostSearch_Comment_Post
  | IPostSearch_Comment_Blog
  | IPostSearch_Comment_Poll
  | IPostSearch_Comment_Question
  | IPostSearch_Comment_Answer;

export type IPostSearch_Question = IPostSearch_Shared & {
  question: {
    _id: string;
    title: string;
    body: string;
  };
  answerCount: number;
  postType: EPostSearchType.QUESTION;
};

export type IPostSearch_Answer = IPostSearch_Shared & {
  answer: {
    _id: string;
    question: { _id: string; title: string };
    body: string;
  };
  postType: EPostSearchType.ANSWER;
};

export type IPostSearch_Blog = IPostSearch_Shared & {
  blog: {
    _id: string;
    title: string;
    published: boolean;
    content: string;
  };
  postType: EPostSearchType.BLOG;
};

export type IPostSearch_Poll = IPostSearch_Shared & {
  poll: {
    _id: string;
    title: string;
    description?: string;
  };
  responseCount: number;
  postType: EPostSearchType.POLL;
};

export type IMemberSearchMembershipData = {
  type: string;
  startDateMs: number;
  renewalPeriodStartDateMs: number;
  gracePeriodEndDateMs: number;
  endDateMs: number;
};

export type IMemberSearch_AttributeData = {
  jobTitle?: string;
  company?: string;
  addressLine0?: string;
  addressLocality?: string;
  addressRegionCode?: string;
  addressAdministrativeArea?: string;
  addressPostalCode?: string;
  email?: string;
  nameTitle?: string;
  primaryPhone?: string;
  [customAttribute: string]: any; //For custom fields
};

export type IMemberSearch = {
  scope: string[];
  objectID: string;
  userId: string;
  profilePictureUrl: string | null;
  accountStatus: EUserAccountStatus;
  displayName: string;
  createdAt: Date;
  memberPriority: number;

  deactivated?: boolean;
  integrationIsMember: boolean;
  hasAccount: boolean;
  roleCache?: {
    role?: AlgoliaERole;
    organizationId?: string;
  };
  membershipData?: IMemberSearchMembershipData;
  adminMemberDirectoryViewable?: IMemberSearch_AttributeData;
  adminMemberDirectorySearchable?: IMemberSearch_AttributeData;
  membersWithMemberStatusMemberDirectoryViewable?: IMemberSearch_AttributeData;
  membersWithMemberStatusMemberDirectorySearchable?: IMemberSearch_AttributeData;
  loggedInMemberDirectoryViewable?: IMemberSearch_AttributeData;
  loggedInMemberDirectorySearchable?: IMemberSearch_AttributeData;
  adminGlobalViewable?: Pick<
    IMemberSearch_AttributeData,
    'jobTitle' | 'company' | 'nameTitle'
  >;
  adminGlobalSearchable?: Pick<
    IMemberSearch_AttributeData,
    'jobTitle' | 'company'
  >;
  membersWithMemberStatusGlobalViewable?: Pick<
    IMemberSearch_AttributeData,
    'jobTitle' | 'company' | 'nameTitle'
  >;
  membersWithMemberStatusGlobalSearchable?: Pick<
    IMemberSearch_AttributeData,
    'jobTitle' | 'company'
  >;
  loggedInGlobalViewable?: Pick<
    IMemberSearch_AttributeData,
    'jobTitle' | 'company' | 'nameTitle'
  >;
  loggedInGlobalSearchable?: Pick<
    IMemberSearch_AttributeData,
    'jobTitle' | 'company'
  >;
};

export type IOrganzationSearch_AttributeData = {
  'company-name'?: string;
  'company-website'?: string;
  'company-addressLine0'?: string;
  'company-addressLocality'?: string;
  'company-addressRegionCode'?: string;
  'company-addressAdministrativeArea'?: string;
  'company-addressPostalCode'?: string;
  'company-email'?: string;
  'company-nameTitle'?: string;
  'company-primaryPhone'?: string;
  [customAttribute: string]: any; //For custom fields
};

export type IOrganizationSearch = {
  scope: string[];
  name: string;
  aboutUs: string;
  dateCreated: Date;
  objectID: string;
  profilePictureUrl: string | null;
  hidden?: boolean;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  website?: string;
  phoneNumber?: string;
  // order: number is here because algolia requires a numeric value for sorting by attribute https://www.algolia.com/doc/guides/managing-results/refine-results/sorting/how-to/sort-by-attribute/#attribute-format
  sponsorTier: { name: string; order: number };
  membershipData?: IMemberSearchMembershipData;
  adminMemberDirectoryViewable?: IOrganzationSearch_AttributeData;
  adminMemberDirectorySearchable?: IOrganzationSearch_AttributeData;
  membersWithMemberStatusMemberDirectoryViewable?: IOrganzationSearch_AttributeData;
  membersWithMemberStatusMemberDirectorySearchable?: IOrganzationSearch_AttributeData;
  loggedInMemberDirectoryViewable?: IOrganzationSearch_AttributeData;
  loggedInMemberDirectorySearchable?: IOrganzationSearch_AttributeData;
  /* adminGlobalViewable?: Pick<
    IOrganzationSearch_AttributeData,
    'jobTitle' | 'company' | 'nameTitle'
  >;
  adminGlobalSearchable?: Pick<
    IOrganzationSearch_AttributeData,
    'jobTitle' | 'company'
  >;
  membersWithMemberStatusGlobalViewable?: Pick<
    IOrganzationSearch_AttributeData,
    'jobTitle' | 'company' | 'nameTitle'
  >;
  membersWithMemberStatusGlobalSearchable?: Pick<
    IOrganzationSearch_AttributeData,
    'jobTitle' | 'company'
  >;
  loggedInGlobalViewable?: Pick<
    IOrganzationSearch_AttributeData,
    'jobTitle' | 'company' | 'nameTitle'
  >;
  loggedInGlobalSearchable?: Pick<
    IOrganzationSearch_AttributeData,
    'jobTitle' | 'company'
  >; */
};

export enum EEventSearchType {
  CONFERENCE = 'CONFERENCE',
  SIMPLE = 'SIMPLE',
}

export type IEventSearch = {
  scope: string[];
  name: string;
  descriptionPlainText: string;
  shortDescription: string;
  timeSpec: {
    startDate: string;
    endDate: string;
  };
  objectID: string;
  bannerImageUrl: string | null;
  eventType: EEventSearchType;
};

export type IFileSearch = {
  scope: string[];
  objectID: string;
  author: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  originalPostId?: string;
  originalCommentId?: string;
  groupId?: string;
  dateCreated: Date;
  file: {
    _id: string;
    name: string;
    type: string;
    size: number;
  };
};

export type ITagSearch = {
  objectID: string;
  name: string;
};

export type IRsvpSearch = {
  objectID: string;
  attendeeId: string;
  eventId: string;
  response: string;
  dateUpdatedUnix: number;
  attendee: {
    _id: string;
    displayName: string;
    email?: string;
    profilePictureUrl?: string;
  };
};
