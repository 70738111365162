import { ApolloCache, StoreObject } from '@apollo/client';
import { RawDraftContentState, convertFromRaw } from 'draft-js';
import { NotificationActivity } from 'getstream';
import qs from 'qs';

import {
  logNotificationInfo,
  logNotificationWarning,
} from '@/imports/logging/ClientLogger';

import { ITradewingActivity } from '../../activityTypes';
import { IEntityIdToEntitiesMap } from '../../hooks/useEntityFetchByIds';
import {
  ETradewingActivityGroupType,
  ICommentAtMentionData,
  ICommentEnrichmentPost,
  ICommentLikeData,
  IEnrichedActivityGroup,
  IEnrichmentPost,
  IParentCommentCommentData,
  IParentPostCommentData,
  IPollVoteData,
  IPostAtMentionData,
  IPostLikeData,
  IQuestionAnswerData,
  ISiblingCommentData,
  TRADEWING_POST_TYPES,
} from './types';

const NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS = 2;

const TENANT_ID_DELIMITER = '-';

export const getPostTypeFromPost = (
  post: IEnrichmentPost
): TRADEWING_POST_TYPES => {
  const postType = post.blog?._id
    ? TRADEWING_POST_TYPES.BLOG
    : post.poll?._id
    ? TRADEWING_POST_TYPES.POLL
    : post.question?._id
    ? TRADEWING_POST_TYPES.QUESTION
    : post.answer?._id
    ? TRADEWING_POST_TYPES.ANSWER
    : post.eventAlertId
    ? TRADEWING_POST_TYPES.EVENT_ALERT
    : TRADEWING_POST_TYPES.POST;
  return postType;
};

export const getPostTypeReadableName = (
  postType: TRADEWING_POST_TYPES
): string => {
  switch (postType) {
    case TRADEWING_POST_TYPES.ANSWER:
      return 'answer';
    case TRADEWING_POST_TYPES.QUESTION:
      return 'question';
    case TRADEWING_POST_TYPES.POST:
      return 'post';
    case TRADEWING_POST_TYPES.BLOG:
      return 'article';
    case TRADEWING_POST_TYPES.POLL:
      return 'poll';
    case TRADEWING_POST_TYPES.EVENT_ALERT:
      return 'event';
  }
};

export const getTextContentForPost = (post: IEnrichmentPost): string | null => {
  const postType = getPostTypeFromPost(post);
  switch (postType) {
    case TRADEWING_POST_TYPES.ANSWER:
      return post.answer?.body
        ? convertFromRaw(
            post.answer.body as RawDraftContentState
          ).getPlainText()
        : null;
    case TRADEWING_POST_TYPES.QUESTION:
      return (
        post.question?.title ||
        (post.question?.body
          ? convertFromRaw(
              post.question.body as RawDraftContentState
            ).getPlainText()
          : null)
      );
    case TRADEWING_POST_TYPES.POST:
      return post.title || post.bodyPlainText || null;
    case TRADEWING_POST_TYPES.BLOG:
      return post.blog?.title || null;
    case TRADEWING_POST_TYPES.POLL:
      return (
        post.poll?.title ||
        (!!post.poll?.descriptionContentState
          ? convertFromRaw(
              post.poll
                .descriptionContentState as unknown as RawDraftContentState
            ).getPlainText()
          : null)
      );
    case TRADEWING_POST_TYPES.EVENT_ALERT:
      return null;
  }
};

export const getIdDetenantifier = (
  tenantId: string
): ((tenantifiedId: string) => string) => {
  return (tenantifiedId) => {
    const detenantedId = tenantifiedId.split(
      `${tenantId}${TENANT_ID_DELIMITER}`
    )[1];
    if (!detenantedId) {
      throw new Error(`Invalid tenanted id ${tenantifiedId}`);
    }
    return detenantedId;
  };
};

export const getActivityGroupTypeFromActivityGroup = (
  activityGroup: NotificationActivity<ITradewingActivity>
): ETradewingActivityGroupType | null => {
  const { group } = activityGroup;
  const activityGroupType = group.split(':')[0];
  switch (activityGroupType) {
    case 'PARENT_POST_COMMENT': {
      return ETradewingActivityGroupType.PARENT_POST_COMMENT;
    }
    case 'POST_LIKE': {
      return ETradewingActivityGroupType.POST_LIKE;
    }
    case 'PARENT_COMMENT_COMMENT': {
      return ETradewingActivityGroupType.PARENT_COMMENT_COMMENT;
    }
    case 'SIBLING_COMMENT': {
      return ETradewingActivityGroupType.SIBLING_COMMENT;
    }
    case 'COMMENT_AT_MENTION': {
      return ETradewingActivityGroupType.COMMENT_AT_MENTION;
    }
    case 'POST_AT_MENTION': {
      return ETradewingActivityGroupType.POST_AT_MENTION;
    }
    case 'QUESTION_ANSWER': {
      return ETradewingActivityGroupType.QUESTION_ANSWER;
    }
    case 'COMMENT_LIKE': {
      return ETradewingActivityGroupType.COMMENT_LIKE;
    }
    case 'POLL_VOTE': {
      return ETradewingActivityGroupType.POLL_VOTE;
    }
    default:
      logNotificationWarning(
        `Could not parse activity group type for ${JSON.stringify(
          activityGroup
        )}`
      );
      return null;
  }
};

type IEntityIds = {
  userIds: string[];
  postIds: string[];
  commentIds: string[];
};

////////////////////////////////////////////
/**    Fetching proper URL for linking   **/
////////////////////////////////////////////

export const getClickHandlerForActivityGroup = (
  activityGroup: IEnrichedActivityGroup,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  switch (activityGroup.activityGroupType) {
    case ETradewingActivityGroupType.PARENT_POST_COMMENT: {
      return getClickHandlerForParentPostComment(
        activityGroup,
        clientCache,
        router
      );
    }
    case ETradewingActivityGroupType.PARENT_COMMENT_COMMENT: {
      return getClickHandlerForParentCommentComment(
        activityGroup,
        clientCache,
        router
      );
    }
    case ETradewingActivityGroupType.SIBLING_COMMENT: {
      return getClickHandlerForSiblingComment(
        activityGroup,
        clientCache,
        router
      );
    }
    case ETradewingActivityGroupType.COMMENT_AT_MENTION: {
      return getClickHandlerForCommentAtMention(
        activityGroup,
        clientCache,
        router
      );
    }
    case ETradewingActivityGroupType.POST_AT_MENTION: {
      return getClickHandlerForPostAtMention(
        activityGroup,
        clientCache,
        router
      );
    }
    case ETradewingActivityGroupType.POST_LIKE: {
      return getClickHandlerForPostLike(activityGroup, clientCache, router);
    }
    case ETradewingActivityGroupType.QUESTION_ANSWER: {
      return getClickHandlerForQuestionAnswer(
        activityGroup,
        clientCache,
        router
      );
    }
    case ETradewingActivityGroupType.COMMENT_LIKE: {
      return getClickHandlerForCommentLike(activityGroup, clientCache, router);
    }
    case ETradewingActivityGroupType.POLL_VOTE: {
      return getClickHandlerForPollVote(activityGroup, clientCache, router);
    }
    default: {
      return () => {};
    }
  }
};

const getPostUrl = (post: ICommentEnrichmentPost | IEnrichmentPost): string => {
  const url = post.blog?._id
    ? `/blog/${post.blog._id}`
    : post.poll?._id
    ? `/poll/${post.poll._id}`
    : post.question?._id
    ? `/question/${post.question._id}`
    : post.answer?._id
    ? `/question/${post.answer.questionId}`
    : `/post/${post._id}`;
  return url;
};

const getClickHandlerForPollVote = (
  activityGroup: IPollVoteData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  const urlToComment = `/poll/${activityGroup.post.poll?._id}`;

  return () => {
    clientCache.evict({
      id: clientCache.identify(activityGroup.post as unknown as StoreObject),
    });
    clientCache.gc();
    router.push(urlToComment);
  };
};

const getClickHandlerForQuestionAnswer = (
  activityGroup: IQuestionAnswerData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  //Want to scroll to answer at some point
  const urlToComment = `/question/${activityGroup.post.question?._id}`;
  return () => {
    clientCache.evict({
      id: clientCache.identify(activityGroup.post as unknown as StoreObject),
    });
    clientCache.gc();
    router.push(urlToComment);
  };
};

const getClickHandlerForParentPostComment = (
  activityGroup: IParentPostCommentData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  const params = { comment: activityGroup.mostRecentComment._id };
  const urlToComment = `${getPostUrl(activityGroup.post)}?${qs.stringify(
    params
  )}`;
  return () => {
    clientCache.evict({
      id: clientCache.identify(activityGroup.post as unknown as StoreObject),
    });
    clientCache.gc();
    router.push(urlToComment);
  };
};

const getClickHandlerForParentCommentComment = (
  activityGroup: IParentCommentCommentData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  const params = { comment: activityGroup.mostRecentComment._id };
  const urlToComment = `${getPostUrl(activityGroup.parentPost)}?${qs.stringify(
    params
  )}`;
  return () => {
    clientCache.evict({
      id: clientCache.identify(
        activityGroup.parentPost as unknown as StoreObject
      ),
    });
    clientCache.gc();
    router.push(urlToComment);
  };
};

const getClickHandlerForSiblingComment = (
  activityGroup: ISiblingCommentData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  const params = { comment: activityGroup.mostRecentComment._id };
  const urlToComment = `${getPostUrl(activityGroup.parentPost)}?${qs.stringify(
    params
  )}`;
  return () => {
    clientCache.evict({
      id: clientCache.identify(
        activityGroup.parentPost as unknown as StoreObject
      ),
    });
    clientCache.gc();
    router.push(urlToComment);
  };
};

const getClickHandlerForCommentAtMention = (
  activityGroup: ICommentAtMentionData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  const params = { comment: activityGroup.mostRecentComment._id };
  const urlToComment = `${getPostUrl(activityGroup.parentPost)}?${qs.stringify(
    params
  )}`;
  return () => {
    clientCache.evict({
      id: clientCache.identify(
        activityGroup.parentPost as unknown as StoreObject
      ),
    });
    clientCache.gc();
    router.push(urlToComment);
  };
};

const getClickHandlerForPostAtMention = (
  activityGroup: IPostAtMentionData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  const urlToComment = `${getPostUrl(activityGroup.post)}`;
  return () => {
    router.push(urlToComment);
  };
};

const getClickHandlerForPostLike = (
  activityGroup: IPostLikeData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  const urlToComment = `${getPostUrl(activityGroup.post)}`;
  return () => {
    router.push(urlToComment);
  };
};

const getClickHandlerForCommentLike = (
  activityGroup: ICommentLikeData,
  clientCache: ApolloCache<any>,
  router: { push: (path: string) => void }
): (() => void) => {
  if (!activityGroup.comment.post) {
    return () => {};
  }
  const params = { comment: activityGroup.comment._id };
  const urlToComment = `${getPostUrl(
    activityGroup.comment.post
  )}?${qs.stringify(params)}`;

  return () => {
    router.push(urlToComment);
  };
};

///////////////////////////////////
/**    Parsing out entity ids   **/
///////////////////////////////////

export const collectEntityIdsFromActivityGroup = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const activityGroupType =
    getActivityGroupTypeFromActivityGroup(activityGroup);
  switch (activityGroupType) {
    case ETradewingActivityGroupType.PARENT_POST_COMMENT: {
      return collectEntityIdsForParentPostComment(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.PARENT_COMMENT_COMMENT: {
      return collectEntityIdsForParentCommentComment(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.SIBLING_COMMENT: {
      return collectEntityIdsForSiblingComment(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.COMMENT_AT_MENTION: {
      return collectEntityIdsForCommentAtMention(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.POST_AT_MENTION: {
      return collectEntityIdsForPostAtMention(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.POST_LIKE: {
      return collectEntityIdsForPostLike(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.QUESTION_ANSWER: {
      return collectEntityIdsForQuestionAnswer(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.COMMENT_LIKE: {
      return collectEntityIdsForCommentLike(activityGroup, tenantId);
    }
    case ETradewingActivityGroupType.POLL_VOTE: {
      return collectEntityIdsForPollVote(activityGroup, tenantId);
    }
    default: {
      return {
        userIds: [],
        postIds: [],
        commentIds: [],
      };
    }
  }
};

const collectEntityIdsForPostLike = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.POST_LIKE}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const postId = detenantifyId(tenantedPostId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) =>
        detenantifyId(activity.actor.id)
      )
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );
  return {
    userIds,
    postIds: [postId],
    commentIds: [],
  };
};

const collectEntityIdsForParentPostComment = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.PARENT_POST_COMMENT}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const postId = detenantifyId(tenantedPostId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) => detenantifyId(activity.actor))
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);

  return {
    userIds,
    postIds: [postId],
    commentIds: [mostRecentCommentId],
  };
};

const collectEntityIdsForParentCommentComment = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const commentTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.PARENT_COMMENT_COMMENT}:`
  )[1];
  const tenantedCommentId = commentTimestampGroup?.split(':')[0];
  if (!tenantedCommentId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const commentId = detenantifyId(tenantedCommentId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) => detenantifyId(activity.actor))
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);

  const postId = detenantifyId(mostRecentActivity.parent_post_id);

  return {
    userIds,
    postIds: [postId],
    commentIds: [commentId, mostRecentCommentId],
  };
};

const collectEntityIdsForSiblingComment = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.SIBLING_COMMENT}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const postId = detenantifyId(tenantedPostId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) => detenantifyId(activity.actor))
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);

  return {
    userIds,
    postIds: [postId],
    commentIds: [mostRecentCommentId],
  };
};

const collectEntityIdsForCommentAtMention = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const commentTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.COMMENT_AT_MENTION}:`
  )[1];
  const tenantedCommentId = commentTimestampGroup?.split(':')[0];
  if (!tenantedCommentId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const commentId = detenantifyId(tenantedCommentId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) => detenantifyId(activity.actor))
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);

  const postId = detenantifyId(mostRecentActivity.parent_post_id);

  return {
    userIds,
    postIds: [postId],
    commentIds: [commentId, mostRecentCommentId],
  };
};

const collectEntityIdsForPostAtMention = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.POST_AT_MENTION}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const postId = detenantifyId(tenantedPostId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) => detenantifyId(activity.actor))
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  return {
    userIds,
    postIds: [postId],
    commentIds: [],
  };
};

const collectEntityIdsForQuestionAnswer = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.QUESTION_ANSWER}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const postId = detenantifyId(tenantedPostId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) => detenantifyId(activity.actor))
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentAnswerId = detenantifyId(mostRecentActivity.foreign_id);

  return {
    userIds,
    postIds: [postId, mostRecentAnswerId],
    commentIds: [],
  };
};

const collectEntityIdsForCommentLike = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const commentTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.COMMENT_LIKE}:`
  )[1];
  const tenantedCommentId = commentTimestampGroup?.split(':')[0];
  if (!tenantedCommentId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const commentId = detenantifyId(tenantedCommentId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities.map((activity) =>
        detenantifyId(activity.actor.id)
      )
    ),
  ];

  const userIds = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  return {
    userIds,
    postIds: [],
    commentIds: [commentId],
  };
};

const collectEntityIdsForPollVote = (
  activityGroup: NotificationActivity<ITradewingActivity>,
  tenantId: string
): IEntityIds => {
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.POLL_VOTE}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return {
      userIds: [],
      postIds: [],
      commentIds: [],
    };
  }

  const postId = detenantifyId(tenantedPostId);

  return {
    userIds: [],
    postIds: [postId],
    commentIds: [],
  };
};

///////////////////////////////////
/** Stitching entities together **/
///////////////////////////////////

export const stitchEntitiesWithActivityGroup = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): IEnrichedActivityGroup | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const activityGroupType =
    getActivityGroupTypeFromActivityGroup(activityGroup);

  switch (activityGroupType) {
    case ETradewingActivityGroupType.PARENT_POST_COMMENT: {
      return stitchEntitiesWithParentPostComment({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.PARENT_COMMENT_COMMENT: {
      return stitchEntitiesWithParentCommentComment({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.SIBLING_COMMENT: {
      return stitchEntitiesWithSiblingComment({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.COMMENT_AT_MENTION: {
      return stitchEntitiesWithCommentAtMention({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.POST_AT_MENTION: {
      return stitchEntitiesWithPostAtMention({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.QUESTION_ANSWER: {
      return stitchEntitiesWithQuestionAnswer({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.POST_LIKE: {
      return stitchEntitiesWithPostLike({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.COMMENT_LIKE: {
      return stitchEntitiesWithCommentLike({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }
    case ETradewingActivityGroupType.POLL_VOTE: {
      return stitchEntitiesWithPollVote({
        activityGroup,
        tenantId,
        entityIdsToEntities,
      });
    }

    default: {
      return null;
    }
  }
};

const stitchEntitiesWithParentPostComment = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): IParentPostCommentData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.PARENT_POST_COMMENT}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return null;
  }

  const postId = detenantifyId(tenantedPostId);
  const post = entityIdsToEntities.posts[postId];
  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) => entityIdsToEntities.users[detenantifyId(activity.actor)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniqueCommenters = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);
  const mostRecentComment = entityIdsToEntities.comments[mostRecentCommentId];

  if (!post || actors.length === 0 || !mostRecentComment) {
    return null;
  }
  return {
    post,
    postType: getPostTypeFromPost(post),
    numUniqueCommenters,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.PARENT_POST_COMMENT,
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
    mostRecentComment,
  };
};

const stitchEntitiesWithParentCommentComment = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): IParentCommentCommentData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const commentTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.PARENT_COMMENT_COMMENT}:`
  )[1];
  const tenantedCommentId = commentTimestampGroup?.split(':')[0];
  if (!tenantedCommentId) {
    return null;
  }

  const commentId = detenantifyId(tenantedCommentId);
  const comment = entityIdsToEntities.comments[commentId];
  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) => entityIdsToEntities.users[detenantifyId(activity.actor)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniqueCommenters = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);

  const postId = detenantifyId(mostRecentActivity.parent_post_id);

  const mostRecentComment = entityIdsToEntities.comments[mostRecentCommentId];
  const parentPost = entityIdsToEntities.posts[postId];

  if (!comment || actors.length === 0 || !mostRecentComment || !parentPost) {
    return null;
  }
  return {
    comment,
    numUniqueCommenters,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.PARENT_COMMENT_COMMENT,
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
    mostRecentComment,
    parentPost,
  };
};

const stitchEntitiesWithSiblingComment = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): ISiblingCommentData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.SIBLING_COMMENT}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return null;
  }

  const postId = detenantifyId(tenantedPostId);

  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) => entityIdsToEntities.users[detenantifyId(activity.actor)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniqueCommenters = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);

  const mostRecentComment = entityIdsToEntities.comments[mostRecentCommentId];
  const parentPost = entityIdsToEntities.posts[postId];

  if (actors.length === 0 || !mostRecentComment || !parentPost) {
    return null;
  }
  return {
    numUniqueCommenters,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.SIBLING_COMMENT,
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
    mostRecentComment,
    parentPost,
  };
};

const stitchEntitiesWithCommentAtMention = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): ICommentAtMentionData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const commentTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.COMMENT_AT_MENTION}:`
  )[1];
  const tenantedCommentId = commentTimestampGroup?.split(':')[0];
  if (!tenantedCommentId) {
    return null;
  }

  const commentId = detenantifyId(tenantedCommentId);
  const comment = entityIdsToEntities.comments[commentId];

  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) => entityIdsToEntities.users[detenantifyId(activity.actor)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniqueCommenters = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentCommentId = detenantifyId(mostRecentActivity.foreign_id);

  const postId = detenantifyId(mostRecentActivity.parent_post_id);

  const mostRecentComment = entityIdsToEntities.comments[mostRecentCommentId];
  const parentPost = entityIdsToEntities.posts[postId];

  if (actors.length === 0 || !comment || !parentPost || !mostRecentComment) {
    return null;
  }
  return {
    numUniqueCommenters,
    comment,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.COMMENT_AT_MENTION,
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
    mostRecentComment,
    parentPost,
  };
};

const stitchEntitiesWithPostAtMention = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): IPostAtMentionData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.POST_AT_MENTION}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return null;
  }

  const postId = detenantifyId(tenantedPostId);
  const post = entityIdsToEntities.posts[postId];

  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) => entityIdsToEntities.users[detenantifyId(activity.actor)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniquePosters = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  if (actors.length === 0 || !post) {
    return null;
  }
  return {
    numUniquePosters,
    post,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.POST_AT_MENTION,
    postType: getPostTypeFromPost(post),
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
  };
};

const stitchEntitiesWithQuestionAnswer = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): IQuestionAnswerData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.QUESTION_ANSWER}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return null;
  }

  const postId = detenantifyId(tenantedPostId);
  const post = entityIdsToEntities.posts[postId];

  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) => entityIdsToEntities.users[detenantifyId(activity.actor)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniqueActors = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  const mostRecentActivity = activityGroup.activities[0];
  const mostRecentAnswerId = detenantifyId(mostRecentActivity.foreign_id);

  const mostRecentAnswer = entityIdsToEntities.posts[mostRecentAnswerId];

  if (actors.length === 0 || !post || !mostRecentAnswer) {
    return null;
  }
  return {
    numUniqueActors,
    post,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.QUESTION_ANSWER,
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
    mostRecentAnswer,
  };
};

const stitchEntitiesWithPostLike = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): IPostLikeData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.POST_LIKE}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return null;
  }

  const postId = detenantifyId(tenantedPostId);
  const post = entityIdsToEntities.posts[postId];

  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) =>
            entityIdsToEntities.users[detenantifyId(activity.actor.id)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniqueActors = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  if (actors.length === 0 || !post) {
    return null;
  }
  return {
    numUniqueActors,
    post,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.POST_LIKE,
    postType: getPostTypeFromPost(post),
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
  };
};

const stitchEntitiesWithCommentLike = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): ICommentLikeData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const commentTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.COMMENT_LIKE}:`
  )[1];
  const tenantedCommentId = commentTimestampGroup?.split(':')[0];
  if (!tenantedCommentId) {
    return null;
  }

  const commentId = detenantifyId(tenantedCommentId);
  const comment = entityIdsToEntities.comments[commentId];

  const uniqueActors = [
    ...new Set(
      activityGroup.activities
        .map(
          (activity) =>
            entityIdsToEntities.users[detenantifyId(activity.actor.id)]
        )
        .filter((actor) => !!actor)
    ),
  ];
  const numUniqueActors = uniqueActors.length;
  const actors = uniqueActors.slice(
    0,
    NUM_ACTORS_NEEDED_FOR_GROUPED_NOTIFICATIONS
  );

  if (actors.length === 0 || !comment) {
    return null;
  }
  return {
    numUniqueActors,
    comment,
    firstActor: actors[0],
    secondActor: actors[1],
    activityGroupType: ETradewingActivityGroupType.COMMENT_LIKE,
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
  };
};

const stitchEntitiesWithPollVote = (options: {
  activityGroup: NotificationActivity<ITradewingActivity>;
  tenantId: string;
  entityIdsToEntities: IEntityIdToEntitiesMap;
}): IPollVoteData | null => {
  const { activityGroup, tenantId, entityIdsToEntities } = options;
  const detenantifyId = getIdDetenantifier(tenantId);
  const postTimestampGroup = activityGroup.group.split(
    `${ETradewingActivityGroupType.POLL_VOTE}:`
  )[1];
  const tenantedPostId = postTimestampGroup?.split(':')[0];
  if (!tenantedPostId) {
    return null;
  }

  const postId = detenantifyId(tenantedPostId);
  const post = entityIdsToEntities.posts[postId];

  const uniqueActors = [
    ...new Set(activityGroup.activities.map((activity) => activity.actor.id)),
  ];
  const numUniqueActors = uniqueActors.length;

  if (!post) {
    return null;
  }
  return {
    numUniqueActors,
    post,
    firstActor: {
      displayName: '?',
      _id: '',
      proxyProfilePictureUrl: null,
      __typename: 'User',
    },
    secondActor:
      numUniqueActors > 1
        ? {
            displayName: '?',
            _id: '',
            proxyProfilePictureUrl: null,
            __typename: 'User',
          }
        : undefined,
    activityGroupType: ETradewingActivityGroupType.POLL_VOTE,
    activity_count: activityGroup.activity_count,
    is_read: activityGroup.is_read,
    is_seen: activityGroup.is_seen,
    id: activityGroup.id,
    updated_at: activityGroup.updated_at,
  };
};
