import { gql } from '@apollo/client';
import {
  Box,
  BoxProps,
  Collapse,
  Link,
  SlideFade,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentNode } from 'graphql';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import { getRouteMatch, useRouteMatch } from '@/hooks/useRouteMatch';
import {
  BoxIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from '@/imports/ui/chakra/feather';
import { useBlueprint } from '@/layouts/components/Blueprint';
import { getExternalUrl } from '@/utilities/getExternalUrl';

import {
  HeaderNavigationMenuLink_NavigationItem,
  HeaderNavigationMenuLink_NavigationItem_children,
} from './graphql-types/HeaderNavigationMenuLink_NavigationItem';

export const HeaderNavigationMenuLink: React.FC<{
  item: HeaderNavigationMenuLink_NavigationItem;
}> & { navigationItemFragment: DocumentNode } = ({ item }) => {
  const { forceMatchHack } = useBlueprint();

  const isRouteMatch =
    useRouteMatch(
      item.href === '/my-company/dashboard/overview' ? '/my-company' : item.href
    ) ||
    (forceMatchHack && item.href === forceMatchHack);

  const styles: BoxProps = {
    textStyle: 'xlBodyStrong',
    p: '6',
    color: isRouteMatch ? 'blue.500' : undefined,
    cursor: 'pointer',
  };

  if (item.href) {
    if (item.isExternal) {
      return (
        <Box
          as="a"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(item.href)}
          {...styles}
        >
          {item.label}
        </Box>
      );
    }
    return (
      <NextLink key={item.href} href={item.href}>
        <Box href={getExternalUrl(item.href)} {...styles}>
          {item.label}
        </Box>
      </NextLink>
    );
  }

  if (item.children.length && item.children.some((child) => child.href)) {
    return <HeaderNavigationMenuDropdownLink item={item} />;
  }

  return null;
};

HeaderNavigationMenuLink.navigationItemFragment = gql`
  fragment HeaderNavigationMenuLink_NavigationItem on NavigationItem {
    label
    href
    isExternal
    children {
      label
      href
      isExternal
    }
  }
`;

const HeaderNavigationMenuDropdownLink: React.FC<{
  item: HeaderNavigationMenuLink_NavigationItem;
}> = ({ item }) => {
  const router = useRouter();

  // the "dropdown" should be automatically open if one of it's children is
  // the current route
  const defaultIsOpen = React.useMemo(() => {
    return item.children
      ? item.children.some((child) => getRouteMatch(child.href, router))
      : false;
  }, [item.children, router]);

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        p="6"
        onClick={onToggle}
        cursor="pointer"
        w="full"
      >
        <Text textStyle="xlBodyStrong">{item.label}</Text>
        {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
      </Stack>
      <Collapse in={isOpen} style={{ width: '100%' }}>
        <Stack bg="gray.50" p="4" spacing="0" alignItems="center">
          {item.children?.map((child, index) => (
            <HeaderNavigationMenuChildLink
              key={index + child.label}
              child={child}
            />
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

const HeaderNavigationMenuChildLink: React.FC<{
  child: HeaderNavigationMenuLink_NavigationItem_children;
}> = ({ child }) => {
  const isRouteMatch = useRouteMatch(child.href);

  if (child.href) {
    if (child.isExternal) {
      return (
        <Stack
          as="a"
          target="_blank"
          rel="noreferrer"
          href={getExternalUrl(child.href)}
          direction="row"
          alignItems="center"
          justifyContent="center"
          textStyle="xlBody"
          color={isRouteMatch ? 'blue.500' : undefined}
          w="full"
          p="4"
        >
          <Box>{child.label}</Box>
          <ExternalLinkIcon boxSize="3" />
        </Stack>
      );
    }

    return (
      <NextLink href={child.href}>
        <Box
          textStyle="xlBody"
          color={isRouteMatch ? 'blue.500' : undefined}
          w="full"
          p="4"
        >
          {child.label}
        </Box>
      </NextLink>
    );
  }

  return null;
};
